import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigConstants } from 'config-constants';
import { Observable } from 'rxjs';
import moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class AdminApiService {

  constructor(private http: HttpClient) {}

  getGameTypes() {
    const url = 'games/types';
    return this.http.get(ConfigConstants.API_URL_V2 + url);
  }

  registerPlayer(payload: any) {
    const url = 'players'
    return this.http.post(ConfigConstants.API_URL_V2 + url, payload)
  }

  getGameTypeROIs(gameTypeIds: number[], start: string, end: string) {
    let url = 'games/types/rois?gameTypeIds=';
    gameTypeIds.forEach((gameTypeId, i) => {
      url += gameTypeId;
      if (i < gameTypeIds.length - 1) {
        url += ','
      }
    })
    if (start) {
      url += '&startDate=' + start;
    }
    if (end) {
      url += '&endDate=' + end;
    }
    return this.http.get(ConfigConstants.API_URL_V2 + url);
  }

  getRoundTypes() {
    const url = 'rounds/types';
    return this.http.get(ConfigConstants.API_URL_V2 + url);
  }


  getGames() {
    return this.http.get(ConfigConstants.API_URL_V2 + 'games');
  }

  getGamesWithoutRounds() {
    return this.http.get(ConfigConstants.API_URL_V2 + 'games?ignoreRounds=true');
  }

  getRounds() {
    return this.http.get(ConfigConstants.API_URL_V2 + 'rounds');
  }

  getRoundsCreatedAfter(t: moment.Moment) {
    return this.http.get(ConfigConstants.API_URL_V2 + 'rounds?createdAfter=' + t.toISOString());
  }

  getRoundActionsList(gameId, roundId) {
    return this.http.get(ConfigConstants.API_URL_V3 + 'gs/actions?roundId=' + roundId +'&gameId='+ gameId + '&withChildren=true');
  }

  getGameActionList(gameId) {
    return this.http.get(ConfigConstants.API_URL_V3 + 'gs/actions?gameId=' + gameId + '&withChildren=true');
  }

  retryAction(actionId: number) {
    return this.http.put(
      ConfigConstants.API_URL_V3 + 'gs/actions/' + actionId + '/retry',
      null
    );
  } 
    
  getUpcomingSurvivorGames(playerId) {
    const urlname = 'upcomingevents?playerId=' + playerId + '&sportId=' + 2;
    return this.http.get(ConfigConstants.API_URL + urlname);
  }

  createNotfication(data) {
    const url = ConfigConstants.API_URL_V2 + 'notifications'
    return this.http.post(url, data)
  }

  getAllNotifications() {
    const url = ConfigConstants.API_URL_V2 + 'notifications'
    return this.http.get(url)
  }

  getLiveSurvivorGames(playerId) {
    const urlname = 'liveevents?playerId=' + playerId + '&sportId=' + 2;
    return this.http.get(ConfigConstants.API_URL + urlname);
  }

  getAllPick6Events() {
    const urlname = 'pick6events?sportId=1';
    return this.http.get(ConfigConstants.API_URL + urlname);
  }

  getGameById(gameId) {
    const url = ConfigConstants.API_URL_V2 + 'games?id=' + gameId;
    return this.http.get(url);
  }

  getGamesByIds(gameIds) {
    let url = ConfigConstants.API_URL_V2 + 'games?';
    gameIds.forEach((id, index) => {
      url += 'id=' + id;
      if (index < gameIds.length - 1) {
        url += '&';
      }
    })
    return this.http.get(url);
  }

  updateAlert(gameId, payload) {
    const url = ConfigConstants.API_URL_V2 + 'games/' + gameId + '/alert'
    return this.http.put(url, payload)
  }

  transitionState(payload) {
    return this.http.post(ConfigConstants.API_URL_V2 + 'games/transitions', payload);
  }

  gameServiceExecuteTransition(payload) {
    return this.http.post(ConfigConstants.API_URL_V3 + 'gs/transitions/execute', payload);
  }

  getLeaderboards(gameId) { 
    const url = ConfigConstants.API_URL_V2 + 'leaderboards?gameId=' + gameId
    return this.http.get(url)
  }

  editFixturePoints(roundId, update) {
    const url = ConfigConstants.API_URL_V2 + 'rounds/' + roundId + '/fixtures/points'
    return this.http.put(url, update)
  }

  updateRoundActions(roundId, update) {
    const url = ConfigConstants.API_URL_V2 + 'rounds/' + roundId + '/actions/times'
    return this.http.put(url, update)
  }

  downloadCsvReport(roundId, ticketStatus, paymentStatus, partnerId, includeSelections) {
    // tslint:disable-next-line:max-line-length
    const url = ConfigConstants.API_URL_V2 + 'rounds/' + roundId + '/report?ticketStatus=' + ticketStatus + '&paymentStatus=' + paymentStatus + '&includeSelections=' + includeSelections;
    const partners =  partnerId ? ('&affiliateId=' + partnerId) : '';
    return this.http.get(url + partners, { responseType: 'text'});
  }

  downloadUpsellCSVReport(roundId, partnerId, ) {
    const url = ConfigConstants.API_URL_V2 + 'rounds/' + roundId + '/report/upsell';
    const partners =  partnerId ? ('?affiliateId=' + partnerId) : '';
    return this.http.get(url + partners, { responseType: 'text'});
  }

  getGamesStats(gameId) {
    const url = ConfigConstants.API_URL_V2 + 'games/' + gameId + '/stats';
    return this.http.get(url);
  }

  downloadCSVParticipantsGolf() {
    const url = ConfigConstants.API_URL_V2 + 'golfparticipants/csv'
    return this.http.get(url, { responseType: 'text'})
  }

  createDailyPayout(roundId, payoutData) {
    payoutData = {
      ...payoutData,
      percentage: parseFloat(payoutData.percentage),
      position: parseInt(payoutData.position)
    }
    const url = ConfigConstants.API_URL_V2 + 'rounds/' + roundId + '/dailypayouts';
    return this.http.post(url, payoutData);  
  }

  getSharingLink(gameId) {
    const url = ConfigConstants.API_URL_V2 + 'social/share?gameId=' + gameId;
    return this.http.get(url);
  }

  createSharingLink(payload) {
    const url = ConfigConstants.API_URL_V2 + 'social/share';
    return this.http.post(url, payload);  
  }

  editSharingLink(payload) {
    const url = ConfigConstants.API_URL_V2 + 'social/share/' + payload.id;
    return this.http.put(url, payload); 
  }

  deleteSharingLink(linkId) {
    const url = ConfigConstants.API_URL_V2 + 'social/share/' + linkId;
    return this.http.delete(url); 
  }

  getGameTickets(gameId) {
    const url = 'games/tickets?gameId=' + gameId;
    return this.http.get(ConfigConstants.API_URL_V2 + url);
  }

  getTickets(gameId, roundId) {
    const url = 'tickets?gameId=' + gameId + '&roundId=' + roundId;
    return this.http.get(ConfigConstants.API_URL_V2 + url);
  }

  getTicketsByGameIds(gameIds) {
    let url = 'tickets?';
    gameIds.forEach((id, index) => {
      url += 'gameId=' + id;
      if (index < gameIds.length - 1) {
        url += '&';
      }
    })
    return this.http.get(ConfigConstants.API_URL_V2 + url);
  }

  getPlayers(playerIds, showPlayerFields) {
    let playerUrlString = '';
    playerIds.forEach((playerId, index) => {
      if (index === 0) {
        playerUrlString = playerUrlString + 'playerId=' + playerId;
      }
      playerUrlString = playerUrlString + '&playerId=' + playerId;
    });
    if (showPlayerFields) {
      playerUrlString += '&showPlayerFields=true'
    }
    const url = 'players?' + playerUrlString;
    return this.http.get(ConfigConstants.API_URL_V2 + url);
  }
  
  getPlayersCreatedBetweenTimes(createdAfter, createdBefore) {
    const url = 'players?createdAfter=' + createdAfter + '&createdBefore=' + createdBefore + '&showPlayerFields=true';
    return this.http.get(ConfigConstants.API_URL_V2 + url);
  }

  refundTickets(ticketId) {
    const url = 'tickets/refund?ticketId=' + ticketId
    return this.http.post(ConfigConstants.API_URL_V2 + url, {})
  }


  getPlayerById(playerId: number) {
    const url = ConfigConstants.API_URL_V2 + 'players?playerId=' + playerId + '&showPlayerFields=true';
    return this.http.get(url);
  }

  SearchPlayer(searchString: string) {
    const url = ConfigConstants.API_URL_V2 + 'players?search=' + searchString + '&showPlayerFields=true';
    return this.http.get(url);
  }

  updatePlayer(playerId: number, player: any) {
    const url = ConfigConstants.API_URL_V2 + 'players/' + playerId;
    return this.http.put(url, player);
  }

  getAffiliates() {
    const url = ConfigConstants.API_URL_V2 + 'affiliates';
    return this.http.get(url);
  }

  getPlayerTickets(playerId: number) {
    const url = ConfigConstants.API_URL_V2 + '/tickets?playerId=' + playerId + '&showSelections=true';
    return this.http.get(url);
  }
  
  sendKycVerifiedMail(playerId: number) {
    const url = ConfigConstants.API_URL_V2 + 'players/kyc-verified-email/' + playerId;
    return this.http.post(url, {});
  }

  excludePlayer(playerId: number) {
    const url = ConfigConstants.API_URL_V2 + 'exclusion/refund'
    return this.http.post(url, {
      playerId
    })
  }

  includePlayer(playerId) {
    const url = ConfigConstants.API_URL_V2 + 'inclusion';
    return this.http.post(url, {
      playerId
    })
  }

  withdrawWallet(playerId) {
    const url = ConfigConstants.API_URL_V2 + 'wallets/player/withdraw'
    return this.http.post(url, {
      playerId
    })
  }

  getPlayerDeposits(playerId: number): Observable<any> {
    const url = ConfigConstants.API_URL_V2 + 'wallets/deposits/list?limit=500&offset=0&playerId=' + playerId;
    return this.http.get(url);
  }

  getPlayerWithdrawals(playerId: number): Observable<any> {
    const url = ConfigConstants.API_URL_V2 + 'wallets/withdrawals/list?limit=500&offset=0&playerId=' + playerId;
    return this.http.get(url);
  }

  getPlayerCheckouts(playerId: number): Observable<any> {
    const url = ConfigConstants.API_URL_V2 + 'wallets/checkouts/list?limit=500&offset=0&playerId=' + playerId;
    return this.http.get(url);
  }

  getPlayerPayouts(playerId: number): Observable<any> {
    const url = ConfigConstants.API_URL_V2 + 'wallets/payouts/list?limit=500&offset=0&playerId=' + playerId;
    return this.http.get(url);
  }

  getPlayerWallets(playerId: number): Observable<any> {
    const url = ConfigConstants.API_URL_V2 + 'wallets/list?limit=500&offset=0&playerId=' + playerId;
    return this.http.get(url);
  }

  getPlayerPaymentTokens(playerId: number): Observable<any> {
    const url = ConfigConstants.API_URL_V2 + 'paymenttokens/list?limit=500&offset=0&playerId=' + playerId;
    return this.http.get(url);
  }


  getDsmSports() {
    let url = 'ds/sports';
    return this.http.get(ConfigConstants.API_URL_V2 + url);
  }

  getDsmAssetTypes(sport: string) {
    let url = `ds/sports/${sport}/gametype-assets`;
    return this.http.get(ConfigConstants.API_URL_V3 + url);
  }

  getDsmTournament(tournamentId: string) {
    let url = `ds/tournaments/${tournamentId}`;
    return this.http.get(ConfigConstants.API_URL_V3 + url);
  }

  getDsmTournaments(tournamentFilters) {
    let url = `ds/tournaments`
    let urlParams = []

    tournamentFilters.sport ? urlParams.push(`sport=${tournamentFilters.sport}`): null;
    tournamentFilters.from ? urlParams.push(`from=${tournamentFilters.from}`): null;
    tournamentFilters.to ? urlParams.push(`to=${tournamentFilters.to}`): null;

    urlParams.length ? url += '?' + urlParams.join('&'): null;

    return this.http.get(ConfigConstants.API_URL_V3 + url);
  }

  postDsmTournamentAssets(tournamentId: string, assetFile: File) {
    const url = 'ds/tournaments/' + tournamentId +  '/assets';
    return this.http.post(ConfigConstants.API_URL_V3 + url, assetFile);
  }

  postDsmTournamentAssetsMeta(tournamentId: string, assetFile: File) {
    const url = 'ds/tournaments/' + tournamentId +  '/assetsMeta';
    return this.http.post(ConfigConstants.API_URL_V3 + url, assetFile);
  }

  getDsmTournamentParticipants(tournamentId) {
    let url = `ds/tournaments/${tournamentId}/participants`
    return this.http.get(ConfigConstants.API_URL_V3 + url);
  }

  getDsmTournamentAssetsMeta(tournamentId) {
    let url = `ds/tournaments/${tournamentId}/assetsMeta`
    return this.http.get(ConfigConstants.API_URL_V3 + url);
  }

  getDsmEvents(eventFilters) {
    let url = `ds/events`;
    let urlParams = [];

    eventFilters.from ? urlParams.push(`from=${eventFilters.from}`): null;
    eventFilters.to ? urlParams.push(`to=${eventFilters.to}`): null;
    eventFilters.running ? urlParams.push(`running=yes`): null;
    eventFilters.sport ? urlParams.push(`sport=${eventFilters.sport}`): null;
    eventFilters.isLive ? urlParams.push(`isLive=yes`): null;

    if (eventFilters.tournamentId) {
      urlParams = [];
      urlParams.push(`tournamentId=${eventFilters.tournamentId}`);
    }

    urlParams.length ? url += '?' + urlParams.join('&'): null;
  
    return this.http.get(ConfigConstants.API_URL_V2 + url);
  }
 
  getDsmEventGameInfo(eventId:string) {
    const url = 'ds/events/' + eventId + '/tree?format=gameinfo';
    return this.http.get(ConfigConstants.API_URL_V2 + url);
  }
  
  getDsmEventMap(eventId:string) {
    const url = 'ds/events/' + eventId + '/tree?format=map';
    return this.http.get(ConfigConstants.API_URL_V2 + url);
  }
  
  getDsmEventIncidents(eventId:string) {
    const url = 'ds/events/' + eventId + '/incidents?sort=desc';
    return this.http.get(ConfigConstants.API_URL_V2 + url);
  }

  getDsmEvent(eventId:string) {
    const url = 'ds/events/' + eventId;
    return this.http.get(ConfigConstants.API_URL_V2 + url);
  }

  getDsmEventPros(eventId:string) {
    const url = 'ds/events/' + eventId + '/players';
    return this.http.get(ConfigConstants.API_URL_V2 + url);
  }

  getDsmIncidents(eventId:string) {
    const url = 'ds/events/' + eventId + '/pro-incidents?sort=desc';
    return this.http.get(ConfigConstants.API_URL_V2 + url);
  }

  syncDsmIncidents(eventId:string) {
    const url = 'ds/events/' + eventId + '/incidents/sync';
    return this.http.post(ConfigConstants.API_URL_V2 + url, "");
  }

  syncDsmEventTree(eventId:string) {
    const url = 'ds/events/' + eventId +  '/sync';
    return this.http.post(ConfigConstants.API_URL_V2 + url, "");
  }

  syncDsmTournamentTree(tournamentId:string) {
    const url = 'ds/tournaments/' + tournamentId +  '/tree';
    return this.http.post(ConfigConstants.API_URL_V2 + url, "");
  }

  DsmEventRecord(eventId:string, record:boolean) {
    const url = 'ds/events/' + eventId +  '/record';
    return this.http.put(ConfigConstants.API_URL_V2 + url, {record: record});
  }
 
  DsmEventDisable(eventId:string, disable:boolean) {
    const url = 'ds/events/' + eventId +  '/disable';
    return this.http.put(ConfigConstants.API_URL_V2 + url, {disable: disable});
  }

  getExistingPayoutAmounts(gameId, roundId, leaderboardId) {
    let url = ConfigConstants.API_URL_V3 + 'payoutamounts' + '?gameId=' + gameId + '&roundId=' + roundId + '&leaderboardId=' + leaderboardId
    return this.http.get(url)
  }

  uploadPayoutsFile(gameId, roundId, leaderboardId, csvfile, payoutAmountType = '') {
    let url = ConfigConstants.API_URL_V2 + 'payoutamounts'
    let fd = new FormData();
    fd.append('gameId', gameId)
    fd.append('roundId', roundId)
    fd.append('leaderboardId', leaderboardId)
    fd.append('payoutAmountType', payoutAmountType)
    fd.append('payoutAmounts', csvfile)
    return this.http.put(url, fd)
  }
  
  getGameRoundLeaderBoard(roundId) {
    const url = `leaderboards?roundId=${roundId}`;
    return this.http.get(ConfigConstants.API_URL_V2 + url);
  }

  executePayout(payoutParams) {
    const url = `wallets/payouts`;
    return this.http.post(ConfigConstants.API_URL_V2 + url, payoutParams);
  }

  getDailyPayoutList(roundId) {
    const url = ConfigConstants.API_URL_V2 + 'rounds/' + roundId + '/dailypayouts';
    return this.http.get(url);
  }

  getExecutedDailyPayoutList(roundId) {
    const url = ConfigConstants.API_URL_V2 + 'wallets/payouts?roundId=' + roundId + '&type=DAILYPAYOUT';
    return this.http.get(url);
  }

  executeDailyPayout(payoutParams) {
    const url = 'wallets/dailypayouts/execute';
    return this.http.post(ConfigConstants.API_URL_V2 + url, payoutParams);
  }

  updatePayout(payoutData, roundId) {
    const url = ConfigConstants.API_URL_V2 + 'rounds/' + roundId + '/dailypayouts';
    return this.http.put(url, [payoutData]);
  }

  getWalletWithdrawals(status) {
    const url = `wallets/withdrawals?status=${status}`;
    return this.http.get(ConfigConstants.API_URL_V2 + url)
  }

  approveWithdrawal(withdrawalId){
    const url = `wallets/withdrawals/` + withdrawalId.id + `/approve`;
    return this.http.post(ConfigConstants.API_URL_V2 + url, withdrawalId.id);
  }

  referWithdrawal(withdrawalId){
    const url = `wallets/withdrawals/` + withdrawalId + `/refer`;
    return this.http.post(ConfigConstants.API_URL_V2 + url, null);
  }

  approveWithdrawals(payload){
    const url = `gs/actions/external/withdrawal`;
    return this.http.post(ConfigConstants.API_URL_V3 + url, payload);
  }

  getExternalActions() {
    const url = '/gs/actions?status=FAILED&name=EXTERNAL_WALLET_WITHDRAWAL'
    return this.http.get(ConfigConstants.API_URL_V3 + url);
  }

  retryExternalWithdrawals(actionId: string){
    const url = `gs/actions/${actionId}/external/withdrawal/retry`;
    return this.http.put(ConfigConstants.API_URL_V3 + url, {});
  }
  
  cancelWithdrawal(withdrawalId) {
    const url = 'wallets/withdrawals/' + withdrawalId + '/cancel'
    return this.http.put(ConfigConstants.API_URL_V2 + url, { withdrawalId })
  }

  manuallyApproveWithdrawal(withdrawalId: any, transactionID: string, type: string) {
    const url = `wallets/withdrawals/` + withdrawalId + `/approve/${type}`;
    return this.http.post(ConfigConstants.API_URL_V2 + url, { paymentTxId: transactionID });
  }

  getPlayerInfo(playerId) {
    let url = ConfigConstants.API_URL_V2 + 'players?playerId=' + playerId
    return this.http.get(url)
  }

  removeExpiredCards(registrationId, playerId) {
    const url = ConfigConstants.API_URL_V2 + 'paymenttokens';
    return this.http.put(url, {
        registrationId, playerId
    })
  }

  creditPlayerWallet(playerId, amount) {
    const creditAmount = parseFloat(amount)
    const url = ConfigConstants.API_URL_V2 + 'wallets/player/' + playerId + '/credit';
    return this.http.post(url, {amount: creditAmount, currency: "GBP"})
  }
  /*Notifications */

  getNotificationCampaigns(): Observable<any> {
    const url = ConfigConstants.API_URL_V2 + 'notification/campaigns' 
    return this.http.get(url) 
  }

  syncContacts(syncData: any): Observable<any>{
    const url = ConfigConstants.API_URL_V2 + 'notification/contact/sync/campaign';
    return this.http.post(url, syncData)
  }

  getSyncStatus(syncId: string): Observable<any>{
    const url = ConfigConstants.API_URL_V2 + `/notification/contact/sync/campaign/status/${syncId}`;
    return this.http.get(url); 
  }

  getTournamentParticipants(extTournamentId: string): Observable<any>{
    const url = ConfigConstants.API_URL_V2 + `/tournaments/stages/participants?extTournamentId=${extTournamentId}`;
    return this.http.get(url); 
  }
  
  getPartnerPlayers() {
    const url = ConfigConstants.API_URL_V2 + `partners/reports/players`;
    return this.http.get(url); 
  }

  downloadCSVParticipantsGolfScraper(type, eventId=0) {
    if(type === 'masters') {
      const url = ConfigConstants.API_URL_V2 + 'golfparticipants/csv?source=masters'
      return this.http.get(url, { responseType: 'text'})
    } else if (type === 'enetpulse') {
      const url = ConfigConstants.API_URL_V2 + 'golfparticipants/csv?source=enetpulse&eventId=' + eventId
      return this.http.get(url, { responseType: 'text'})
    }
  }

  getTournamentStages(sportId: number) {
    const url = ConfigConstants.API_URL_V2 + 'tournaments/stages?sportId=' + sportId;
    return this.http.get(url);
  }
 
  syncTournamentStages(sportId: number) {
    return this.http.post(ConfigConstants.API_URL_V2 + 'tournaments/stages/sync', { sportId });
  }

  getTournamentStageEvents(extTournamentId) {
    const url = 'tournaments/stages/events?extTournamentId=' + extTournamentId;
    return this.http.get(ConfigConstants.API_URL_V2 + url)
  }

  getTodaysEventTypeCountsGroupedByUTM(eventType: string, affiliate: string) {
    let url = 'analytics/eventcounts?eventTypes=' + eventType + '&groupByUTM=true';
    if (affiliate.length) {
      url += '&affiliate=' + affiliate;
    }
    return this.http.get(ConfigConstants.API_URL_V2 + url)
  }

  getTodaysEventTypeCounts(eventTypes: string[], gameType: string, affiliate: string) {
    let url = 'analytics/eventcounts?gameType=' + gameType + '&eventTypes=';
    eventTypes.forEach((et, i) => {
      url += et;
      if (i < eventTypes.length - 1) {
        url += ','
      }
    })
    if (affiliate.length) {
      url += '&affiliate=' + affiliate;
    }
    return this.http.get(ConfigConstants.API_URL_V2 + url)
  }

  getEventCounts(source: string, medium: string, campaign: string, startDate: string, affiliateId?: number) {
    let url = 'analytics/eventcounts?';
    if (source.length) {
      url += 'source=' + source + '&';
    }
    if (medium.length) {
      url += 'medium=' + medium + '&';
    }
    if (campaign.length) {
      url += 'campaign=' + campaign + '&';
    }
    if (startDate.length) {
      url += 'startDate=' + startDate + '&';
    }
    if (affiliateId) {
      url += 'affiliateId=' + affiliateId + '&';
    }

    if (url.endsWith('&')) {
      url = url.substring(0, url.length - 1);
    }

    return this.http.get(ConfigConstants.API_URL_V2 + url)
  }

  getUTMs(affiliateId?: number) {
    let url = 'analytics/utms?';
    
    if (affiliateId) {
      url += 'affiliateId=' + affiliateId;
    }

    return this.http.get(ConfigConstants.API_URL_V2 + url)
  }

  getTicketCounts(timeFrame: string) {
    let url = 'analytics/ticketcounts?since=';
    
    switch (timeFrame) {
      case 'TODAY':
        url += moment().startOf('day').toISOString();
        break;
      case 'THIS_WEEK':
        url += moment().startOf('week').toISOString();
        break;
      case 'THIS_MONTH':
        url += moment().startOf('month').toISOString();
        break;
    }
    
    return this.http.get(ConfigConstants.API_URL_V2 + url)
  }

  getTodaysBoughtTicketsByUtm(query: any) {
    const url = 'tickets' +
      '?utmSource=' + query.utmSource + 
      '&utmMedium=' + query.utmMedium + 
      '&utmCampaign=' + query.utmCampaign +
      '&createdSince=' + moment().startOf('day').toISOString() +
      '&paymentStatus=SUCCEEDED';
    return this.http.get(ConfigConstants.API_URL_V2 + url);
  }

  getGsIssues() {
    return this.http.get(ConfigConstants.API_URL_V3 + 'gs/issues')
  }

  updateGsIssue(issueId, adminNote) {
    const url = ConfigConstants.API_URL_V3 + 'gs/issues/' + issueId;
    return this.http.put(url, { adminNote })
  }

  deleteFixture(roundId: number, roundFixtureId: number) {
    const url = ConfigConstants.API_URL_V2 + 'rounds/'+ roundId +'/fixtures'
    return this.http.delete(url, { body: {
      roundFixtureId
    } })
  }

  getEntityFixtures() {
    const url = ConfigConstants.API_URL_V3 + 'entity-sports/nba/fixtures?perPage=30&paged=1'
    return this.http.get(url)
  }

  getEntityFixtureInfo(matchId) {
    console.log(matchId)
    const url = ConfigConstants.API_URL_V3 + 'entity-sports/nba/fixtures/' + matchId
    return this.http.get(url)
  }

  getBroadageTournaments() {
    return this.http.get(ConfigConstants.API_URL_V2 + 'broadage/tournaments');
  }

  getBroadageTournamentFixtures(tournamentId) {
    return this.http.get(ConfigConstants.API_URL_V2 + 'broadage/tournaments/' + tournamentId + '/fixtures');
  }

  getBroadageTournamentFixtureIncidents(tournamentId, fixtureId) {
    return this.http.get(ConfigConstants.API_URL_V2 + 'broadage/tournaments/' + tournamentId + '/fixtures/' + fixtureId + '/incidents');
  }

  getBroadageTeamPlayers(teamId) {
    return this.http.get(ConfigConstants.API_URL_V2 + 'broadage/teams/' + teamId + '/players');
  }

  getMySportsFeedFixtures(date) {
    return this.http.get('http://localhost:9000/test', { params: { date }});
  }

  getMySportsFeedBoxScore(dateGameString) {
    return this.http.get('http://localhost:9000/test/boxscore', { params: { dateGameString }});
  }

  getMySportsFeedPlayByPlay(dateGameString) {
    return this.http.get('http://localhost:9000/test/playbyplay', { params: { dateGameString }});
  }

  getMySportsFeedLineup(dateGameString) {
    return this.http.get('http://localhost:9000/test/lineup', { params: { dateGameString }});
  }

  getDailyPayoutTemplates() {
    return this.http.get(ConfigConstants.API_URL_V2 + 'dailypayouts/templates');
  }

  createDailyPayoutTemplate(template) {
    return this.http.post(ConfigConstants.API_URL_V2 + 'dailypayouts/templates', template);
  }

  updateDailyPayoutTemplate(templateId, template) {
    return this.http.put(ConfigConstants.API_URL_V2 + 'dailypayouts/templates/' + templateId, template);
  }

  deleteDailyPayoutTemplate(templateId) {
    return this.http.delete(ConfigConstants.API_URL_V2 + 'dailypayouts/templates/' + templateId);
  }

  getHeatmapInfo() {
    const url = 'heatmap';
    // return this.http.get('https://api.prod.networkgaming.co.uk/v3/heatmap')
    return this.http.get(ConfigConstants.API_URL_V3 + url)
  }

  // getBroadageTournamentFixtures(tournamentId) {
  //   return this.http.get(ConfigConstants.API_URL_V2 + 'broadage/tournaments/' + tournamentId + '/fixtures');
  // }
  getServices() {
    return this.http.get(ConfigConstants.API_URL_V2 + 'services');
  }

  listActions() {
    return this.http.get(ConfigConstants.API_URL_V2 + 'services/actions');
  }

  executeAction(action) {
    return this.http.post(ConfigConstants.API_URL_V2 + 'services/actions', action);
  }

  getSports() {
    return this.http.get(ConfigConstants.API_URL_V2 + 'sports');
  }

  getPromos() {
    return this.http.get(ConfigConstants.API_URL_V3 + 'promos?select=all');
  }

  createPromo(promoData: any) {
    return this.http.post(ConfigConstants.API_URL_V2 + 'promos', { ...promoData, })
  }

  updatePromo(promoId: number, promoData: any) {
    const url = ConfigConstants.API_URL_V2 + 'promos/' + promoId
    return this.http.put(url,  { ...promoData })
  }

  updatePromoAffiliates(promoId: number, affiliateData: any) {
    const url = ConfigConstants.API_URL_V2 + 'promos/' + promoId + '/affiliates'
    return this.http.put(url, affiliateData)
  }

  deletePromo(offerId: number){
    const url = ConfigConstants.API_URL_V2 + 'promos/' + offerId
    return this.http.delete(url)
  }

  createPromoImages(data: any) {
    const url = ConfigConstants.API_URL_V2 + 'promos/images';
    const payload = new FormData();
    payload.append("desktopImage", data.desktopImage);
    payload.append("desktopImageName", data.desktopImage.name);
    payload.append("mobileImage", data.mobileImage);
    payload.append("mobileImageName", data.mobileImage.name);
    return this.http.post(url, payload)
  }

  getOffers() {
    return this.http.get(ConfigConstants.API_URL_V3 + 'offers');
  }

  createOffer(offerData: any) {
    const affiliateId = Number(offerData.affiliateId)
    return this.http.post(ConfigConstants.API_URL_V2 + 'offers', { ...offerData, affiliateId })
  }

  updateOffer(offerId: number, offerData: any) {
    const affiliateId = Number(offerData.affiliateId)
    const url = ConfigConstants.API_URL_V2 + 'offers/' + offerId
    return this.http.put(url,  { ...offerData, affiliateId })
  }

  deleteOffer(offerId: number){
    const url = ConfigConstants.API_URL_V2 + 'offers/' + offerId
    return this.http.delete(url)
  }

  getGameFeedbacksByGameId(gameId) {
    return this.http.get(ConfigConstants.API_URL_V2 + 'games/feedback?gameId=' + gameId);
  }

  getGameFeedbacksCreatedAfter(createdAfter) {
    return this.http.get(ConfigConstants.API_URL_V2 + 'games/feedback?createdAfter=' + createdAfter);
  }

  listApis() {
    return this.http.get(ConfigConstants.API_URL_V2 + 'apis');
  }

  getPools(gameIds) {
    let url = 'pools?';
    gameIds.forEach((id, index) => {
      url += 'gameId=' + id;
      if (index < gameIds.length - 1) {
        url += '&';
      }
    })
    return this.http.get(ConfigConstants.API_URL_V2 + url);
  }
}
