import { Injectable } from "@angular/core";
import { ApiService } from "projects/player/src/api-service/api.service";
import { UtilsService } from "./utils.service";

@Injectable({
  providedIn: "root",
})
export class GameTypeService {

  constructor(private apiService: ApiService, private utilsService: UtilsService) {}

  public readonly GolfPick6GameTypeID: number = 1;
  public readonly FootballSurvivorGameTypeID: number = 2; // game engine transitions
  public readonly FootballShortGameTypeID: number = 3;
  public readonly HorseRaceDonkeyGameTypeID: number = 4;
  public readonly FootballSGSGameTypeID: number = 5; // game engine transitions
  public readonly TournamentSurvivorTypeID: number = 6;
  public readonly FootballSurvivorGSGameTypeID: number = 7; // gs transition
  public readonly HorseRaceFibonacciGameTypeID: number = 8;
  public readonly FootballSGSGSGameTypeID: number = 9; // gs transition
  public readonly FootballShortGSGameTypeID: number = 10; // gs transition
  public readonly HorseRaceFibonacciGSGameTypeID: number = 11; //gs transition
  public readonly HorseRaceDonkeyGSGameTypeID: number = 12; //gs transition
  public readonly GolfPick6GSGameTypeID: number = 13; //gs transition
  public readonly TournamentSurvivorGSTypeID: number = 14; //gs transition
  public readonly BasketballSGSGameTypeID: number = 15;
  public readonly NflOnTheMoneyGSGameTypeID: number = 17; //gs transition
  public readonly CricketSnakesAndLaddersGameTypeID: number = 18; 
  public readonly CricketOnTheMoneyGSGameTypeID: number = 19; //gs transition

  public GameServiceTransitions = [
    this.FootballSurvivorGSGameTypeID,
    this.FootballSGSGSGameTypeID,
    this.FootballShortGSGameTypeID,
    this.HorseRaceFibonacciGSGameTypeID,
    this.HorseRaceDonkeyGSGameTypeID,
    this.GolfPick6GSGameTypeID,
    this.TournamentSurvivorGSTypeID,
    this.NflOnTheMoneyGSGameTypeID,
    this.BasketballSGSGameTypeID,
    this.CricketSnakesAndLaddersGameTypeID,
    this.CricketOnTheMoneyGSGameTypeID
  ];

  public AmericanFootballGameTypes = [
    this.NflOnTheMoneyGSGameTypeID,
  ]

  public HorseRacingGameTypes = [
    this.HorseRaceDonkeyGameTypeID,
    this.HorseRaceDonkeyGSGameTypeID,
    this.HorseRaceFibonacciGameTypeID,
    this.HorseRaceFibonacciGSGameTypeID,
  ];

  public FootballGameTypes = [
    this.FootballSurvivorGameTypeID,
    this.FootballSurvivorGSGameTypeID,
    this.FootballShortGameTypeID,
    this.FootballShortGSGameTypeID,
    this.FootballSGSGameTypeID,
    this.FootballSGSGSGameTypeID,
    this.TournamentSurvivorTypeID,
    this.TournamentSurvivorGSTypeID,
  ];

  public CricketGameTypes = [
    this.CricketSnakesAndLaddersGameTypeID,
    this.CricketOnTheMoneyGSGameTypeID
  ]

  public GolfGameTypes = [this.GolfPick6GameTypeID, this.GolfPick6GSGameTypeID];

  IsHorseRacingGameType(gameTypeId: number): boolean {
    return this.HorseRacingGameTypes.includes(gameTypeId);
  }

  IsFootballGameType(gameTypeId: number): boolean {
    return this.FootballGameTypes.includes(gameTypeId);
  }

  IsGolfGameType(gameTypeId: number): boolean {
    return this.GolfGameTypes.includes(gameTypeId);
  }

  IsAmericanFootballGameType(gameTypeId: number): boolean {
    return this.AmericanFootballGameTypes.includes(gameTypeId);
  }

  IsCricketGameType(gameTypeId: number): boolean {
    return this.CricketGameTypes.includes(gameTypeId);
  }

  ResolveGoBackUrl(gameTypeId, gameId) {
    switch (gameTypeId) {
      case this.GolfPick6GameTypeID:
      case this.GolfPick6GSGameTypeID:
        return "game/pick6?gameId=" + gameId;
      case this.FootballSurvivorGameTypeID:
      case this.FootballSurvivorGSGameTypeID:
        return "game/survivor-game?gameId=" + gameId;
      case this.FootballShortGameTypeID:
      case this.FootballShortGSGameTypeID:
        return "game/short?gameId=" + gameId;
      case this.HorseRaceDonkeyGameTypeID:
      case this.HorseRaceDonkeyGSGameTypeID:
        return "game/horse-racing?gameId=" + gameId;
      case this.HorseRaceFibonacciGameTypeID:
      case this.HorseRaceFibonacciGSGameTypeID:
        return "game/horse-racing-fib?gameId=" + gameId;
      case this.FootballSGSGameTypeID:
      case this.FootballSGSGSGameTypeID:
        return "game/sgs?gameId=" + gameId;
      case this.TournamentSurvivorTypeID:
      case this.TournamentSurvivorGSTypeID:
        return "game/wcs?gameId=" + gameId;
      case this.NflOnTheMoneyGSGameTypeID:
        return "game/nfl-on-the-money?gameId=" + gameId;
      case this.CricketSnakesAndLaddersGameTypeID:
        return "game/cricket-snakes-and-ladders?gameId=" + gameId;
      default:
        console.log("ResolveGoBackUrl - gameTypeId unknown: ", gameTypeId);
        return "";
    }
  }

  ResolveGameName(gameTypeId: number) {
    switch (gameTypeId) {
      case this.GolfPick6GameTypeID:
      case this.GolfPick6GSGameTypeID:
        return "Pick6";
      case this.FootballSurvivorGameTypeID:
      case this.FootballSurvivorGSGameTypeID:
        return "Survivor";
      case this.FootballShortGameTypeID:
      case this.FootballShortGSGameTypeID:
        return "Big5";
      case this.HorseRaceDonkeyGameTypeID:
      case this.HorseRaceDonkeyGSGameTypeID:
        return "Top Half";
      case this.FootballSGSGameTypeID:
      case this.FootballSGSGSGameTypeID:
        return "SGS";
      case this.TournamentSurvivorTypeID:
      case this.TournamentSurvivorGSTypeID:
        return "Tournament Survivor";
      case this.HorseRaceFibonacciGameTypeID:
      case this.HorseRaceFibonacciGSGameTypeID:
        return "Shout";
      case this.NflOnTheMoneyGSGameTypeID:
        return "On The Money";
      case this.BasketballSGSGameTypeID:
        return "SGS Basketball"
      case this.CricketSnakesAndLaddersGameTypeID:
        return "Snakes & Ladders"
      case this.CricketOnTheMoneyGSGameTypeID:
        return "Cricket OTM"
      default:
        console.log("ResolveGameName - gameTypeId unknown: ", gameTypeId);
        return "";
    }
  }

  ResolveGameImagePath(gameTypeId) {
    switch (gameTypeId) {
      case this.GolfPick6GameTypeID:
      case this.GolfPick6GSGameTypeID:
        return "../../../assets/icons/pick6Small.svg";
      case this.FootballSurvivorGameTypeID:
      case this.FootballSurvivorGSGameTypeID:
      case this.TournamentSurvivorTypeID:
      case this.TournamentSurvivorGSTypeID:
        return "../../../assets/icons/survivorSmall.svg";
      case this.FootballShortGameTypeID:
      case this.FootballShortGSGameTypeID:
        return "../../../assets/icons/big5Small.svg";
      case this.HorseRaceDonkeyGameTypeID:
      case this.HorseRaceDonkeyGSGameTypeID:
      case this.HorseRaceFibonacciGameTypeID:
      case this.HorseRaceFibonacciGSGameTypeID:
        return "../../../assets/icons/horse_racing_lobby_small.svg";
      case this.FootballSGSGameTypeID:
      case this.FootballSGSGSGameTypeID:
        return "../../../assets/icons/sgsSmall.svg";
      case this.NflOnTheMoneyGSGameTypeID:
        return "../../../assets/icons/sgsSmall.svg"; //TODO remove
        // return "../../../assets/icons/nflOnTheMoney.svg"; //TODO uncomment
      case this.CricketSnakesAndLaddersGameTypeID:
        return "../../../assets/icons/sgsSmall.svg"; // TODO change here
      default:
        console.log("ResolveGameImagePath - gameTypeId unknown: ", gameTypeId);
        return "";
    }
  }

  ResolveGameImagePathForCalendar(gameTypeId) {
    switch (gameTypeId) {
      case this.GolfPick6GameTypeID:
      case this.GolfPick6GSGameTypeID:
        return "../../../assets/icons/pick6Small.svg";
      case this.FootballSurvivorGameTypeID:
      case this.FootballSurvivorGSGameTypeID:
        return "../../../assets/icons/survivorBig.svg";
      case this.TournamentSurvivorTypeID:
      case this.TournamentSurvivorGSTypeID:
        return "../../../assets/icons/trophy.svg";
      case this.FootballShortGameTypeID:
      case this.FootballShortGSGameTypeID:
        return "../../../assets/icons/big5Small.svg";
      case this.HorseRaceDonkeyGameTypeID:
      case this.HorseRaceDonkeyGSGameTypeID:
      case this.HorseRaceFibonacciGameTypeID:
      case this.HorseRaceFibonacciGSGameTypeID:
        return "../../../assets/icons/horse_racing_lobby_small.svg";
      case this.FootballSGSGameTypeID:
      case this.FootballSGSGSGameTypeID:
        return "../../../assets/icons/sgsSmall.svg";
      case this.NflOnTheMoneyGSGameTypeID:
        return "../../../assets/icons/sgsSmall.svg"; //TODO remove
        // return "../../../assets/icons/nflOnTheMoney.svg"; //TODO uncomment
      case this.CricketSnakesAndLaddersGameTypeID:
        return "../../../assets/icons/sgsSmall.svg"; // TODO change here
      default:
        console.log("ResolveGameImagePath - gameTypeId unknown: ", gameTypeId);
        return "";
    }
  }

  ResolveRoundRailsPath(gameTypeId: number) {
    switch (gameTypeId) {
      case this.GolfPick6GameTypeID:
      case this.GolfPick6GSGameTypeID:
        return "round-rails/pick6-round-rails";
      case this.FootballSurvivorGameTypeID:
      case this.FootballSurvivorGSGameTypeID:
        return "round-rails/survivor-round-rails";
      case this.FootballShortGameTypeID:
      case this.FootballShortGSGameTypeID:
        return "round-rails/sfg-round-rails";
      case this.HorseRaceDonkeyGameTypeID:
      case this.HorseRaceDonkeyGSGameTypeID:
        return "round-rails/horse-racing-round-rails";
      case this.HorseRaceFibonacciGameTypeID:
      case this.HorseRaceFibonacciGSGameTypeID:
        return "round-rails/horse-racing-fib-round-rails";
      case this.FootballSGSGameTypeID:
      case this.FootballSGSGSGameTypeID:
      case this.BasketballSGSGameTypeID:
        return "round-rails/sgs-round-rails";
      case this.TournamentSurvivorTypeID:
      case this.TournamentSurvivorGSTypeID:
        return "round-rails/wcs-round-rails";
      case this.NflOnTheMoneyGSGameTypeID:
        return "round-rails/nfl-on-the-money-round-rails";
      case this.CricketSnakesAndLaddersGameTypeID:
        return "round-rails/cricket-snl-round-rails";
      case this.CricketOnTheMoneyGSGameTypeID:
        return "round-rails/cricket-on-the-money-round-rails";
      default:
        console.log("resolveRoundRailsPath - gameTypeId unknown: ", gameTypeId);
        return "";
    }
  }

  ResolvePickerUrl(gameTypeId: number) {
    switch (gameTypeId) {
      
      case this.GolfPick6GameTypeID:
      case this.GolfPick6GSGameTypeID:
        return "picker/pick6";
      
      case this.FootballSurvivorGameTypeID:
      case this.FootballSurvivorGSGameTypeID:
        return "picker/survivor-round";
      
      case this.FootballShortGameTypeID:
      case this.FootballShortGSGameTypeID:
        return "picker/short";
      
      case this.HorseRaceDonkeyGameTypeID:
      case this.HorseRaceDonkeyGSGameTypeID:
        return "picker/horse-racing";
      
      case this.HorseRaceFibonacciGameTypeID:
      case this.HorseRaceFibonacciGSGameTypeID:
        return "picker/horse-racing-fib";
      
      case this.FootballSGSGameTypeID:
      case this.FootballSGSGSGameTypeID:
      case this.BasketballSGSGameTypeID:
        return "picker/sgs-picker";
      
      case this.TournamentSurvivorTypeID:
      case this.TournamentSurvivorGSTypeID:
        return "in-play/wcs";
      case this.NflOnTheMoneyGSGameTypeID:
        return "picker/otm-picker";
      case this.CricketSnakesAndLaddersGameTypeID:
         return "picker/snakes-and-ladders-picker";
      case this.CricketOnTheMoneyGSGameTypeID:
        return "picker/cricket-otm-picker";

      default:
        console.log("resolveRoundRailsPath - gameTypeId unknown: ", gameTypeId);
        return "";
    }
  }

  lobbyInfoModalHeaderTitle(gameTypeId: number) {
    switch (gameTypeId) {
      case this.GolfPick6GameTypeID:
      case this.GolfPick6GSGameTypeID:
        return "Major prizes, major fun!";
      case this.FootballSurvivorGameTypeID:
      case this.FootballSurvivorGSGameTypeID:
        return "Stay in it to win it!";
      case this.FootballShortGameTypeID:
      case this.FootballShortGSGameTypeID:
        return "5 big matches, boosted";
      case this.HorseRaceDonkeyGameTypeID:
      case this.HorseRaceDonkeyGSGameTypeID:
        return "You're always in with a shout!";
      case this.HorseRaceFibonacciGameTypeID:
      case this.HorseRaceFibonacciGSGameTypeID:
        return "You're always in with a shout!";
      case this.FootballSGSGameTypeID:
      case this.FootballSGSGSGameTypeID:
      case this.BasketballSGSGameTypeID:
        return "Predict. Survive. Win.";
      case this.TournamentSurvivorTypeID:
      case this.TournamentSurvivorGSTypeID:
        return "Predict. Survive. Win.";
      case this.NflOnTheMoneyGSGameTypeID:
        return "Every yard counts!";
      case this.CricketSnakesAndLaddersGameTypeID:
        return "Snakes And Ladeeersss"; // TODO change here
      default:
        return "";
    }
  }

  ResolveGameTitle(gameData): Promise<string> {
    return new Promise(resolve => {
      switch (gameData.gameTypeId) {
        case this.CricketOnTheMoneyGSGameTypeID:
          this.apiService.getGameById(gameData.id).subscribe(res => {
            let participantId;
            const fixture = res['data']['rounds'][0]['fixtures'][0];
            res['data']['rounds'][0]['questions'].forEach(q => {
              if (participantId && participantId !== q.roundProfessionals[0].participantId) {
                resolve(fixture.homeName + ' vs ' + fixture.awayName);
              }
              participantId = q.roundProfessionals[0].participantId;
            })
            if (fixture.homeDsID === participantId) {
              resolve(fixture.homeName + ' Innings vs ' + fixture.awayName);
            } else {
              resolve(fixture.awayName + ' Innings vs ' + fixture.homeName);
            }
          });
          break;
        case this.NflOnTheMoneyGSGameTypeID:
          resolve(gameData.fixtureSummary.awayName + ' @ ' + gameData.fixtureSummary.homeName);
        case this.BasketballSGSGameTypeID:
          resolve(this.utilsService.getNames(gameData.fixtureSummary.awayName) + ' @ ' + this.utilsService.getNames(gameData.fixtureSummary.homeName));
        case this.FootballSGSGameTypeID:
        case this.FootballSGSGSGameTypeID:
          resolve(this.utilsService.getNames(gameData.fixtureSummary.homeName) + ' VS ' + this.utilsService.getNames(gameData.fixtureSummary.awayName));
        case this.CricketSnakesAndLaddersGameTypeID:
          resolve(this.utilsService.getNames(gameData.fixtureSummary.homeName) + ' VS ' + this.utilsService.getNames(gameData.fixtureSummary.awayName));
        default:
          resolve(gameData.name);
      }
    })

  }

  UsesGameServiceTransition(gameTypeId: number): boolean {
    return this.GameServiceTransitions.includes(gameTypeId);
  }
}
