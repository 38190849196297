import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import moment from 'moment';
import { ModalService } from '../modules/root-modal/root-modal.service';
import { isBefore, isEqual } from 'date-fns';
import { AbbreviationService } from './abbreviation.service';
import { ConfigConstants } from 'config-constants';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  currencyId = ConfigConstants.CURRENCY_ID;

  resourceViewRoundStateMappings = {
    'gamedash': ['EARLY', 'OPEN', 'LIVE', 'RESULTED', 'CANCELLED'],
    'picker': ['EARLY', 'OPEN', 'LIVE', 'RESULTED'],
    'lb': ['LIVE'],
    'resulted-lb': ['RESULTED'],
    'ticket-details': ['OPEN', 'LIVE', 'RESULTED'],
  }

  constructor(private toastService: ToastrService, private router: Router, private modalService: ModalService, private abbreviationService: AbbreviationService) { }

  showToast(message: string, toastType: 'success' | 'error' | 'warning' | 'info' | 'danger' | 'warn', title?: string, delay = 5000) {
    const toastConfig = {
      closeButton: true,
      progressBar: true,
      timeOut: delay
    };

    switch (toastType) {
      case 'success':
        this.toastService.success(message, title || 'Success', toastConfig);
        break;
      case 'info':
        this.toastService.info(message, title || 'info', toastConfig);
        break;
      case 'warning' || 'warn':
        this.toastService.warning(message, title || 'Warning', toastConfig);
        break;
      case 'error' || 'danger':
        this.toastService.error(message, title || 'Error', toastConfig);
        break;
      default:
        this.toastService.show(message, title || 'Alert', toastConfig);
    }

  }

  checkCountDownTime(scheduledTime: string): number {
    const timeDiff = moment().diff(scheduledTime, 'minutes');
    return Math.abs(timeDiff)
  }

  getGameTypePickerUrl(gameData) {
    if (gameData) {
      switch (gameData.gameTypeName) {
        case 'SGS BASKETBALL':
        case 'SGS FOOTBALL':
        case 'SGS FOOTBALL:GS':
        case 'SINGLE GAME SURVIVOR:GS':
        case 'SINGLE GAME SURVIVOR':
          return 'picker/sgs-picker';

        case 'FOOTBALL SURVIVOR':
        case 'TOURNAMENT SURVIVOR':
          return 'picker/survivor-round';
        case 'FOOTBALL SURVIVOR:GS':
          return 'picker/survivor-round';

        case 'BASKETBALL SURVIVOR':
            return 'picker/basketball-survivor-round';
        case 'BASKETBALL SURVIVOR:GS':
          return 'picker/basketball-survivor-round';
      
        case 'ICE HOCKEY SURVIVOR':
          return 'picker/ice-hockey-survivor-round';
        
        case 'GOLF PICK 6':
          return 'picker/pick6';
        case 'GOLF PICK 6:GS':
          return 'picker/pick6';

        case 'FOOTBALL SHORT FORMAT':
          return 'picker/short';
        case 'FOOTBALL SHORT FORMAT:GS':
          return 'picker/short';

        case 'HORSE RACE FIB UK':
          return 'picker/horse-racing-fib';
        case 'HORSE RACE FIB UK:GS':
          return 'picker/horse-racing-fib';

        case 'HORSE RACE DONKEY':
          return 'picker/horse-racing';
        case 'HORSE RACE DONKEY:GS':
          return 'picker/horse-racing';

        case 'NFL ON THE MONEY:GS':
        case 'NFL ON THE MONEY':
          return 'picker/otm-picker';

        case 'CRICKET SNAKES AND LADDERS':
          return 'picker/snakes-and-ladders-picker';

        case 'CRICKET ON THE MONEY':
          return 'picker/cricket-otm-picker';

        // case 'TOURNAMENT SURVIVOR':
        //   return 'footballsurvivor';
        // case 'TOURNAMENT SURVIVOR:GS':
        //   return 'footballsurvivor';
      }
    }
  }

  getGameTypeLuckyDip(gameData) {
    if (gameData) {
      switch (gameData.gameTypeName) {
        case 'SINGLE GAME SURVIVOR':
        case 'SINGLE GAME SURVIVOR:GS':
        case 'HORSE RACE FIB UK':
        case 'HORSE RACE FIB UK:GS':
        case 'SGS FOOTBALL':
        case 'SGS FOOTBALL:GS':
        case 'SGS BASKETBALL':
        case 'CRICKET ON THE MONEY':
          return true;
        default:
          return false;
      }
    }
  }

  getRoundFromRoundsSummary(gameData) {
    let round;
    if (gameData.currentRoundId > 0) round = gameData.roundsSummary.find(rd => rd.id === gameData.currentRoundId)
    else round = gameData.roundsSummary[0];

    return round;
  }

  getEntryScoreType(gameData) {

    if (gameData) {
      switch (gameData.gameTypeName) {
        case 'SINGLE GAME SURVIVOR':
        case 'SINGLE GAME SURVIVOR:GS':
        case 'SGS FOOTBALL:GS':
        case 'SGS FOOTBALL':
        case 'SGS BASKETBALL':
        case 'SGS FOOTBALL':
        case 'SGS FOOTBALL:GS':
          return 'lives';

        case 'FOOTBALL SURVIVOR':
        case 'FOOTBALL SURVIVOR:GS':
        case 'BASKETBALL SURVIVOR':
        case 'ICE HOCKEY SURVIVOR':
          return 'status';

        case 'GOLF PICK 6':
        case 'GOLF PICK 6:GS':
          return 'winnings';

        case 'FOOTBALL SHORT FORMAT':
        case 'FOOTBALL SHORT FORMAT:GS':
          return 'points';

        case 'HORSE RACE FIB UK':
        case 'HORSE RACE FIB UK:GS':
          return 'points';

        case 'HORSE RACE DONKEY':
        case 'HORSE RACE DONKEY:GS':
          return 'points';

        case 'NFL ON THE MONEY:GS':
        case 'NFL ON THE MONEY':
          return 'winnings'

        case 'CRICKET ON THE MONEY':
          return 'runs';
        // case 'TOURNAMENT SURVIVOR':
        //   return 'footballsurvivor';
        // case 'TOURNAMENT SURVIVOR:GS':
        //   return 'footballsurvivor';
      }
    }
  }

  getCmsGameType(gameData) {
    const affName = localStorage.getItem('AFF_NAME')
    const isFSBAff = affName === 'fitzdaressportsbook'

    if (gameData && !isFSBAff) {
      switch (gameData.gameTypeName) {
        case 'SINGLE GAME SURVIVOR':
        case 'SINGLE GAME SURVIVOR:GS':
        case 'SGS FOOTBALL':
        case 'SGS FOOTBALL:GS':
          return 'SGS Soccer rules';
        
        case 'SGS BASKETBALL':
          return 'SGS Basketball rules';

        case 'FOOTBALL SURVIVOR':
        case 'FOOTBALL SURVIVOR:GS':
          return 'Football Survivor rules';

        case 'BASKETBALL SURVIVOR':
          return 'Basketball Survivor rules';

        case 'ICE HOCKEY SURVIVOR':
          return 'Ice Hockey Survivor rules';

        case 'GOLF PICK 6':
        case 'GOLF PICK 6:GS':
          return 'Pick6 Rules';

        case 'FOOTBALL SHORT FORMAT':
        case 'FOOTBALL SHORT FORMAT:GS':
          return 'Big5 rules';

        case 'HORSE RACE FIB UK':
        case 'HORSE RACE FIB UK:GS':
          return 'Shout Racing rules';

        case 'HORSE RACE DONKEY':
        case 'HORSE RACE DONKEY:GS':
          return 'Shout Racing rules';
      }
    }
    else if (gameData && isFSBAff) {
      switch (gameData.gameTypeName) {
        case 'SINGLE GAME SURVIVOR':
        case 'SINGLE GAME SURVIVOR:GS':
        case 'SGS FOOTBALL':
        case 'SGS FOOTBALL:GS':
          return 'SGS Soccer rules (FSB)';
        
        case 'SGS BASKETBALL':
          return 'SGS Basketball rules (FSB)';

        case 'FOOTBALL SURVIVOR':
        case 'FOOTBALL SURVIVOR:GS':
          return 'Football Survivor rules (FSB)';

        case 'BASKETBALL SURVIVOR':
          return 'Basketball Survivor rules (FSB)';

        case 'ICE HOCKEY SURVIVOR':
          return 'Ice Hockey Survivor rules (FSB)';

        case 'GOLF PICK 6':
        case 'GOLF PICK 6:GS':
          return 'Pick6 Rules (FSB)';

        case 'FOOTBALL SHORT FORMAT':
        case 'FOOTBALL SHORT FORMAT:GS':
          return 'Big5 rules (FSB)';

        case 'HORSE RACE FIB UK':
        case 'HORSE RACE FIB UK:GS':
          return 'Shout Racing rules (FSB)';

        case 'HORSE RACE DONKEY':
        case 'HORSE RACE DONKEY:GS':
          return 'Shout Racing rules (FSB)';
      }
    }
  }

  getLiveUrlWithParams(gameData, roundData) {
    let url = ''
    let queryParams: {
      gameId: number,
      roundId: number,
      ticketId?: number,
      tab?: string,
    } = {
      gameId: gameData['id'],
      roundId: roundData['id']
    }
    if (gameData) {
      switch (gameData.gameTypeName) {
        case 'SINGLE GAME SURVIVOR':
        case 'SGS BASKETBALL':
        case 'SGS FOOTBALL':
        case 'SGS FOOTBALL:GS':
          url = 'in-play/sgs';
          if (!gameData.paidTickets) {
            queryParams = {
              ...queryParams,
              tab: 'live'
            }
          } else {
            queryParams = {
              ...queryParams,
              ticketId: gameData.paidTickets[0].id,
              tab: 'live'
            }
          }
          break;
        case 'SINGLE GAME SURVIVOR:GS':
          url = 'in-play/sgs';
          if (!gameData.paidTickets) {
            queryParams = {
              ...queryParams,
              tab: 'live'
            }
          } else {
            queryParams = {
              ...queryParams,
              ticketId: gameData.paidTickets[0].id,
              tab: 'live'
            }
          }
          break;

        case 'FOOTBALL SURVIVOR':
          url = 'leaderboard/survivor';
          if (!gameData.paidTickets) {
            queryParams = {
              ...queryParams,
              ticketId: gameData.paidTickets[0].id
            }
          }
          break;
        case 'FOOTBALL SURVIVOR:GS':
          url = 'leaderboard/survivor';
          if (!gameData.paidTickets) {
            queryParams = {
              ...queryParams,
              ticketId: gameData.paidTickets[0].id
            }
          }
          break; 

          case 'BASKETBALL SURVIVOR':
            url = 'leaderboard/basketball-survivor';
            if (!gameData.paidTickets) {
              queryParams = {
                ...queryParams,
                ticketId: gameData.paidTickets[0].id
              }
            }
            break;

            case 'ICE HOCKEY SURVIVOR':
              url = 'leaderboard/ice-hockey-survivor';
              if (!gameData.paidTickets) {
                queryParams = {
                  ...queryParams,
                  ticketId: gameData.paidTickets[0].id
                }
              }
              break;

        case 'GOLF PICK 6':
          url = 'leaderboard/pick6';
          break;
        case 'GOLF PICK 6:GS':
          url = 'leaderboard/pick6';
          break;

        case 'FOOTBALL SHORT FORMAT':
          url = 'leaderboard/short';
          break;
        case 'FOOTBALL SHORT FORMAT:GS':
          url = 'leaderboard/short';
          break;

        case 'HORSE RACE FIB UK':
          url = 'in-play/horse-racing-fib';
          break;
        case 'HORSE RACE FIB UK:GS':
          url = 'in-play/horse-racing-fib';
          break;

        case 'HORSE RACE DONKEY':
          url = 'in-play/horse-racing';
          break;
        case 'HORSE RACE DONKEY:GS':
          url = 'in-play/horse-racing';
          break;

        case 'NFL ON THE MONEY:GS':
        case 'NFL ON THE MONEY':
          url = 'in-play/otm';
          if (gameData.paidTickets) {
            queryParams = {
              ...queryParams,
              ticketId: gameData.paidTickets[0].id,
              tab: "live"
            }
          }
          break;

        case 'CRICKET ON THE MONEY':
          url = 'in-play/cricket-otm';
          if (gameData.paidTickets) {
            queryParams = {
              ...queryParams,
              ticketId: gameData.paidTickets[0].id,
              tab: "live"
            }
          }
          break;

        // case 'TOURNAMENT SURVIVOR':
        //   return 'in-play/wcs';
        // case 'TOURNAMENT SURVIVOR:GS':
        //   return 'in-play/wcs';
      }
    }
    return {
      url,
      queryParams
    }
  }

  getResultedUrlWithParams(gameData, roundData) {
    let url = ''
    let queryParams: {
      gameId: number,
      roundId: number,
      ticketId?: number,
      tab?: string,
      state?: string,
    } = {
      gameId: gameData['id'],
      roundId: roundData['id']
    }
    if (gameData) {
      console.log(gameData)
      switch (gameData.gameTypeName) {
        case 'SINGLE GAME SURVIVOR':
        case 'SGS FOOTBALL':
        case 'SGS BASKETBALL':
        case 'SGS FOOTBALL:GS':
        case 'SINGLE GAME SURVIVOR:GS':
          url = 'in-play/sgs';
          if (!gameData.paidTickets) {
            queryParams = {
              ...queryParams,
              tab: 'live'
            }
          } else {
            queryParams = {
              ...queryParams,
              ticketId: gameData.paidTickets[0].id,
              tab: 'live'
            }
          }
          break;

        case 'FOOTBALL SURVIVOR':
          url = 'leaderboard/survivor/resulted';
          if (gameData.paidTickets?.length) {
            queryParams = {
              ...queryParams,
              ticketId: gameData.paidTickets[0].id
            }
          }
          break;
        case 'FOOTBALL SURVIVOR:GS':
          url = 'leaderboard/survivor/resulted';
          if (gameData.paidTickets?.length) {
            queryParams = {
              ...queryParams,
              ticketId: gameData.paidTickets[0].id
            }
          }
          break;

        case 'BASKETBALL SURVIVOR':
          url = 'leaderboard/basketball-survivor/resulted';
          if (gameData.paidTickets?.length) {
            queryParams = {
              ...queryParams,
              ticketId: gameData.paidTickets[0].id
            }
          }
          break;
        
          case 'ICE HOCKEY SURVIVOR':
            url = 'leaderboard/ice-hockey-survivor/resulted';
            if (gameData.paidTickets?.length) {
              queryParams = {
                ...queryParams,
                ticketId: gameData.paidTickets[0].id
              }
            }
            break;
            
        case 'GOLF PICK 6':
          url = 'leaderboard/pick6/resulted';
          break;
        case 'GOLF PICK 6:GS':
          url = 'leaderboard/pick6/resulted';
          break;

        case 'FOOTBALL SHORT FORMAT':
          url = 'leaderboard/short/resulted';
          break;
        case 'FOOTBALL SHORT FORMAT:GS':
          url = 'leaderboard/short/resulted';
          break;

        case 'HORSE RACE FIB UK':
          url = 'in-play/horse-racing-fib';
          break;
        case 'HORSE RACE FIB UK:GS':
          url = 'in-play/horse-racing-fib';
          break;

        case 'HORSE RACE DONKEY':
          url = 'in-play/horse-racing/resulted';
          break;
        case 'HORSE RACE DONKEY:GS':
          url = 'in-play/horse-racing/resulted';
          break;

          case 'NFL ON THE MONEY:GS':
          case 'NFL ON THE MONEY':
            url = 'in-play/otm';
            queryParams = {
              ...queryParams,
              ticketId: gameData.paidTickets[0].id,
              tab: 'leaderboard',
              state: 'RESULTED'
            }
            break;

        case 'CRICKET ON THE MONEY':
          url = 'in-play/cricket-otm';
          queryParams = {
            ...queryParams,
            tab: 'leaderboard',
            state: 'RESULTED'
          }
          if (gameData.paidTickets?.length) {
            queryParams.ticketId = gameData.paidTickets[0].id;
          }
          break;

        // case 'TOURNAMENT SURVIVOR':
        //   return 'in-play/wcs';
        // case 'TOURNAMENT SURVIVOR:GS':
        //   return 'in-play/wcs';
      }
    }
    return {
      url,
      queryParams
    }
  }

  filterToSportName(filterName) {
    switch (filterName) {
      case "All":
        return 'All'
      case "Football":
        return 'Football'
      case "Racing":
        return 'Horse Racing'
      case "Golf":
        return 'Golf'
    }
  }

  filterToGameType(filterName, gameTypeId) {
    switch (filterName) {
      case "All":
        return true;
      case "Football Survivor":
        return gameTypeId === 2 || gameTypeId === 7;
      case "Big5":
        return gameTypeId === 3 || gameTypeId === 10;
      case "Single Game Survivor":
        return gameTypeId === 5 || gameTypeId === 9;
      case "Tournament Survivor":
        return gameTypeId === 6 || gameTypeId === 14;
      case "Basketball Survivor":
        return gameTypeId === 20;
      case "Ice Hockey Survivor":
        return gameTypeId === 21;
      case "Shout Racing":
        return gameTypeId === 8 || gameTypeId === 11;
      case "Top Half":
        return gameTypeId === 4 || gameTypeId === 12;
    }
  }

  getGameTypeName(gameData) {
    if (!gameData) return ''
    switch (gameData.gameTypeName) {
      case 'SINGLE GAME SURVIVOR':
      case 'SINGLE GAME SURVIVOR:GS':
      case 'SGS FOOTBALL':
      case 'SGS FOOTBALL:GS':
        return 'singlegamesurvivor';
      
      case 'SGS BASKETBALL':
        return 'sgsbasketball';

      case 'FOOTBALL SURVIVOR':
        return 'footballsurvivor';
      case 'FOOTBALL SURVIVOR:GS':
        return 'footballsurvivor';

      case 'BASKETBALL SURVIVOR':
        return 'basketballsurvivor';

      case 'ICE HOCKEY SURVIVOR':
        return 'icehockeysurvivor';

      case 'GOLF PICK 6':
        return 'fantasygolf';
      case 'GOLF PICK 6:GS':
        return 'fantasygolf';

      case 'FOOTBALL SHORT FORMAT':
        return 'big5';
      case 'FOOTBALL SHORT FORMAT:GS':
        return 'big5';


      case 'HORSE RACE FIB UK':
        return 'shoutracing';
      case 'HORSE RACE FIB UK:GS':
        return 'shoutracing';

      case 'HORSE RACE DONKEY':
        return 'donkey';
      case 'HORSE RACE DONKEY:GS':
        return 'donkey';

      case 'TOURNAMENT SURVIVOR':
        return 'footballsurvivor';
      case 'TOURNAMENT SURVIVOR:GS':
        return 'footballsurvivor';

      case 'NFL ON THE MONEY:GS':
      case 'NFL ON THE MONEY':
        return 'onthemoney';

      case 'CRICKET SNAKES AND LADDERS':
        return 'snakesandladders';

      case 'CRICKET ON THE MONEY':
        return 'cricketonthemoney'
    }
  }

  getBasketGameName(gameData) {
    if (!gameData) return ''
    switch (gameData.gameTypeName) {
      case 'SINGLE GAME SURVIVOR':
      case 'SINGLE GAME SURVIVOR:GS':
      case 'SGS GAME SURVIVOR:GS':
        return 'Single Game Survivor';
      
      case 'SGS FOOTBALL':
      case 'SGS FOOTBALL:GS':
        return 'Football Single Game Survivor';
      
      case 'SGS BASKETBALL':
        return 'Basketball Single Game Survivor';

      case 'FOOTBALL SURVIVOR':
      case 'FOOTBALL SURVIVOR:GS':
        return 'Football Survivor';

      case 'BASKETBALL SURVIVOR':
        return 'Basketball Survivor';

        case 'ICE HOCKEY SURVIVOR':
          return 'Ice Hockey Survivor';
        
      case 'GOLF PICK 6':
      case 'GOLF PICK 6:GS':
        return 'Fantasy Golf';

      case 'FOOTBALL SHORT FORMAT':
      case 'FOOTBALL SHORT FORMAT:GS':
        return 'Big5';


      case 'HORSE RACE FIB UK':
      case 'HORSE RACE FIB UK:GS':
        return 'Shout Racing';

      case 'HORSE RACE DONKEY':
      case 'HORSE RACE DONKEY:GS':
        return 'Donkey';

      case 'TOURNAMENT SURVIVOR':
      case 'TOURNAMENT SURVIVOR:GS':
        return 'Tournament Survivor';

      case 'NFL ON THE MONEY:GS':
      case 'NFL ON THE MONEY':
        return 'On The Money';

      case 'CRICKET SNAKES AND LADDERS':
        return 'Snakes And Ladders';

      case 'CRICKET ON THE MONEY':
        return 'Cricket On The Money';
    }
  }

  getLastName(name) {
    const names = name.split(' ');
    if (names.length === 3 && names[1].length < 3) {
      return names[2];
    }

    return names.slice(1).join(' ');
  }

  splitParticipantName(name) {
    if (name.indexOf(' ') === -1) {
      return { firstName: name, lastName: ' ' };
    }

    const names = name.split(' ');
    if (names.length === 3 && names[1].length < 3) {
      return {
        firstName: names.slice(0, 2).join(' '),
        lastName: names[2]
      };
    }

    return {
      firstName: names[0],
      lastName: names.slice(1).join(' ')
    };
  }

  encodeQueryParam(queryParamName, data) {
    return data.map((key) => { return [queryParamName, key].map(encodeURIComponent).join("="); }).join("&");
  }

  getMaxPaidTicketsLength(tickets) {
    const paidTickets = tickets.filter(it => it.paymentStatus !== 'NOTATTEMPTED')
    return paidTickets.length || 0;
  }

  getMaxTicketsFromGameFields(fields) {
    if (!fields) return 0;
    return parseInt(fields.find(it => it['name'] === 'maxTickets')['value']);
  }

  getRakeFromGameFields(fields) {
    if (!fields) return 0;
    return parseInt(fields.find(it => it['name'] === 'rakePercentage')['value']);
  }

  getTicketName(name) {

    if (!name || name.length > 2) return "";

    const idx = parseInt(name);
    if (idx >= 0 && idx <= 26) {
      return String.fromCharCode(idx + 64);
    }

    // If name > 26, return first two characters A+A, A+B and so on
    if (idx > 26) {
      const firstChar = String.fromCharCode((idx - 1) / 26 + 64);
      const secondChar = String.fromCharCode((idx - 1) % 26 + 65);
      return firstChar + secondChar;
    }
    
    return "";
  }

  parseFieldDefaultValue(field: Object) {
    let value;

    switch (field['valueType']) {
      case 'integer':
        value = field['value'] ? parseInt(field['value']) : "";
        break;
      case 'float':
        value = field['value'] ? parseFloat(field['value']) : "";
        break;
      case 'datetime':
        value = field['value'] ? new Date(field['value']).toISOString().substr(0, 16) : "";
        break;
      case 'boolean':
        value = field['value'] ? JSON.parse(field['value']) : false;
        break;
      default:
        value = field['value'] || "";
    }

    return value
  }

  getSportName(gameData) {
    if (!gameData) return ''
    switch (gameData.sportName) {
      case 'Basketball':
        return 'sgsbasketball';
      case 'Football':
        return 'football';
      case 'Golf':
        return 'golf';
      case 'Horse Racing':
        return 'horseracing';
      case 'American Football':
        return 'americanfootball';
      case 'Basketball':
        return 'sgsbasketball';
      case 'Cricket':
        return 'snakesandladders';
    }
  }

  sortLobbyGames(games: any[]){
    return games.sort((a: any,b: any) => {
      const roundA = this.getRoundFromRoundsSummary(a)
      let transitionA = null;
      const roundB = this.getRoundFromRoundsSummary(b)
      let transitionB = null;
      if (!roundA.transitions?.length) return 1;
      if (!roundB.transitions?.length) return -1;
      if(roundA.state === 'EARLY'){
        transitionA = roundA.transitions.find(t => t.name === 'MOVE_EARLY_TO_OPEN')
      } else if (roundA.state === 'OPEN') {
        transitionA = roundA.transitions.find(t => t.name === 'MOVE_OPEN_TO_LIVE')
      } else if (roundA.state === "RESULTED") {
        transitionA = roundA.transitions.find(t => t.name === 'MOVE_LIVE_TO_RESULTED')
        if(!transitionA) transitionA = roundA.transitions.find(t => t.name === 'MOVE_OPEN_TO_LIVE')
      } else {
        transitionA = roundA.transitions.find(t => t.name === 'MOVE_OPEN_TO_LIVE')
      }
      if(roundB.state === 'EARLY'){
        transitionB = roundB.transitions.find(t => t.name === 'MOVE_EARLY_TO_OPEN')
      } else if (roundB.state === 'OPEN') {
        transitionB = roundB.transitions.find(t => t.name === 'MOVE_OPEN_TO_LIVE')
      } else if (roundB.state === "RESULTED") {
        transitionB = roundB.transitions.find(t => t.name === 'MOVE_LIVE_TO_RESULTED')
        if(!transitionB) transitionB = roundB.transitions.find(t => t.name === 'MOVE_OPEN_TO_LIVE')
      } else {
        transitionB = roundB.transitions.find(t => t.name === 'MOVE_OPEN_TO_LIVE')
      }
      if (!transitionA) {
        return 1;
      }
      if (!transitionB) {
        return -1;
      }
      const dateA = new Date(transitionA?.scheduled)
      const dateB = new Date(transitionB?.scheduled)
      if(isEqual(dateA, dateB)){
        return roundA.id > roundB.id ? 1 : -1; 
      } else {
        if (roundA.state === "RESULTED" && ["RESULTED", "CANCELLED"].includes(roundB.state)) {
          return isBefore(dateA, dateB) ? 1 : -1;
        }
        return isBefore(dateB, dateA) ? 1 : -1;
      }
    })
  }

  handleNoScroll(add: boolean = false){
    const doc = document.getElementsByClassName("layout_container")[0];
    if(doc){
      if(add){
        doc.classList.add("no_scroll")
        // window.scrollTo(0,0)
      } else doc.classList.remove("no_scroll")
    }
  }

  getWindowLeft() {
    const doc = document.getElementsByClassName("main_container")[0]
    if (doc) {
      const dd = doc.getBoundingClientRect()
      return dd.left + 'px';
    } else return '0px'
  }

  getWindowWidth() {
    const doc = document.getElementsByClassName("main_container")[0]
    if (doc) {
      const dd = doc.getBoundingClientRect()
      return dd.width + 'px';
    } else return '0px'
  }

  getWindowTop() {
    const doc = document.getElementsByClassName("main_container")[0]
    if (doc) {
      const dd = doc.getBoundingClientRect()
      return dd.top + 'px';
    } else return '0px'
  }

  getLeaderboardUrlData(gameObj, roundId, ticketObj, forLive: boolean = true, forResulted: boolean = false){
    const returnObj: {
      url: string,
      queryParams: {
        gameId?: number,
        roundId?: number,
        ticketId?: number,
        selectedTicketId?: number,
        tab?: string,
        state?: string,
      }
    } = {
      url: "",
      queryParams: {
        gameId: gameObj.id,
        roundId: roundId
      }
    }
    const gameType = this.getGameTypeName(gameObj)
    switch(gameType){
      case 'big5':
        returnObj.url = forLive ? 'leaderboard/short': 'leaderboard/short/resulted';
        break;
      case 'singlegamesurvivor':
      case 'sgsbasketball':
        returnObj.url = 'in-play/sgs';
        returnObj.queryParams.ticketId = ticketObj.id;
        break;
      case 'shoutracing':
        returnObj.url = 'in-play/horse-racing-fib';
        returnObj.queryParams.ticketId = ticketObj.id;
        break;
      case 'footballsurvivor':
        returnObj.url = forLive ? 'leaderboard/survivor': 'leaderboard/survivor/resulted';
        if(ticketObj) returnObj.queryParams.ticketId = ticketObj.id;
        break;
      case 'basketballsurvivor':
        returnObj.url = forLive ? 'leaderboard/basketball-survivor': 'leaderboard/basketball-survivor/resulted';
        if(ticketObj) returnObj.queryParams.ticketId = ticketObj.id;
        break;

        case 'icehockeysurvivor':
          returnObj.url = forLive ? 'leaderboard/ice-hockey-survivor': 'leaderboard/ice-hockey-survivor/resulted';
          if(ticketObj) returnObj.queryParams.ticketId = ticketObj.id;
          break;
      case 'onthemoney':
        returnObj.url = 'in-play/otm';
        returnObj.queryParams.ticketId = ticketObj.id;
        returnObj.queryParams.tab = forLive ? 'live' : 'leaderboard';
        if(forResulted){
          returnObj.queryParams.state = 'RESULTED'
        }
        break;
      case 'cricketonthemoney':
        returnObj.url = 'in-play/cricket-otm';
        returnObj.queryParams.ticketId = ticketObj.id;
        returnObj.queryParams.tab = forLive ? 'live' : 'leaderboard';
        if(forResulted){
          returnObj.queryParams.state = 'RESULTED'
        }
        break;
      case 'snakesandladders':
        returnObj.url = 'in-play/snakes-and-ladders';
        returnObj.queryParams.ticketId = ticketObj.id;
        break;
    }
    return returnObj;
  }

  handleBasketViewScroll(){
    // const inter = setInterval(() => {
    //   const layout_container = document.getElementsByClassName('layout_container')[0]
    //   const pay_now = document.getElementsByClassName('pay_now')[0]
    //   if(layout_container){
    //     layout_container.scrollTop = layout_container.scrollHeight
    //     setTimeout(() => {
    //       if(pay_now){
    //         pay_now.scrollIntoView()
    //       }
    //     }, 10)
    //     clearInterval(inter)
    //   }
    // }, 10)
  }

  allowedBottomNav(): string[] {
    return [
      '/',
      '/mygames',
      '/wallet',
      '/wallet/deposit',
      '/wallet/deposit-status',
      '/wallet/insufficient-funds',
      '/wallet/checkout',
      '/wallet/checkout-status',
      '/wallet/withdraw',
      '/wallet/history',
      '/wallet/self-exclude',
      '/wallet/deposit-limit',
      '/wallet/timeout',
      '/wallet/cards',
      '/myaccount',
      '/myaccount/preferences',
      '/myaccount/language',
      '/notification-center',
      '/change-password',
      '/payment-history',
      '/displayname',
      '/reach-us',
    ]
  }

  getEntriesFromRoundFields(round, gameData) {
    const roundFields = round && round['fields'];
    let count = 0
    if(round.roundNumber !== 1) {
      count = this.getEntriesFromGameData(gameData)
    }
    const regularCount = roundFields?.find(it => it['name'] === 'regularCount' && it['group'] === 'entriesCount')?.value ?? 0;
    const earlyCount = roundFields?.find(it => it['name'] === 'earlyCount' && it['group'] === 'entriesCount')?.value ?? 0;
    const rebuyCount = roundFields?.find(it => it['name'] === 'rebuyCount' && it['group'] === 'entriesCount')?.value ?? 0;
    const bonusCount = roundFields?.find(it => it['name'] === 'bonusCount' && it['group'] === 'entriesCount')?.value ?? 0;
    return Number(regularCount) + Number(earlyCount) + Number(rebuyCount) + Number(bonusCount) + count;
  }

  getEntriesFromGameData(gameData): number {
    const currentRound = this.getRoundFromRoundsSummary(gameData);
    const pool = currentRound.pools.find(p => p.currencyId === this.currencyId)
    const regularCount = pool.regularCount ?? 0
    const earlyCount = pool.earlyCount ?? 0
    const rebuyCount = pool.rebuyCount ?? 0
    const bonusCount = pool.bonusCount ?? 0
    return Number(regularCount) + Number(earlyCount) + Number(rebuyCount) + Number(bonusCount);
  }

  /**
   * This functions checks if a user can view resource (Game, round..)
   * If the selected resource does not belong to the player's affiliate, reroute user to error screen
   * Or if player is trying to access a game which is in a state which can not be accessed.
   * @params {game, round}
   */
  async checkIfPlayerCanViewResource(game, round, resourceType) {
    const jwttoken = localStorage.getItem('JWT_TOKEN');
    if (!jwttoken) return true;
    const user = new JwtHelperService().decodeToken(jwttoken).user;
    const canViewResource = game.affiliates.find(affiliate => affiliate.affiliateId === user.affiliateId);
    const stateMap = this.resourceViewRoundStateMappings[resourceType]
    if (!stateMap) return true;
    if (!canViewResource || !(stateMap).includes(round.state)) {
      this.router.navigate(['/resource-not-found']);
      return false;
    }
    return true;
  }

  openSelectionAddedSuccess(): void {
    this.modalService.open('selection-added-modal');
  }

  closeBasketSuccess() {
    this.modalService.close('basket-success-modal');
  }

  getYardsAwayColor(yardsAway: number) {
    if (yardsAway <= 10) {
      return '#26FF56'
    } else if (yardsAway > 10 && yardsAway <= 30) {
      return '#87E645'
    } else if (yardsAway > 30 && yardsAway <= 50) {
      return '#ABDC3F'
    } else if (yardsAway > 50 && yardsAway <= 75) {
      return '#CFD338'
    } else if (yardsAway > 75 && yardsAway <= 100) {
      return '#FFCE45'
    } else if (yardsAway > 100 && yardsAway <= 125) {
      return '#FDBB30'
    } else if (yardsAway > 125 && yardsAway <= 150) {
      return '#F79A32'
    } else if (yardsAway > 150 && yardsAway <= 175) {
      return '#ED6535'
    } else if (yardsAway > 175) {
      return '#E63E37';
    } else {
      return '#E63E37';
    }
  }
  getYardsAwayColorLB(yardsAway: number) {
    if (yardsAway <= 200) {
      return '#26FF56'
    } else if (yardsAway > 200 && yardsAway <= 300) {
      return '#87E645'
    } else if (yardsAway > 300 && yardsAway <= 400) {
      return '#ABDC3F'
    } else if (yardsAway > 400 && yardsAway <= 500) {
      return '#CFD338'
    } else if (yardsAway > 500 && yardsAway <= 600) {
      return '#FFCE45'
    } else if (yardsAway > 600 && yardsAway <= 700) {
      return '#FDBB30'
    } else if (yardsAway > 700 && yardsAway <= 800) {
      return '#F79A32'
    } else if (yardsAway > 800 && yardsAway <= 900) {
      return '#ED6535'
    } else if (yardsAway > 900) {
      return '#E63E37';
    } else {
      return '#E63E37';
    }
  }

  getQuarter(headerData) {
    if (!headerData) return "";
    if (!headerData.lastIncidentDetails) return "-th Quarter";
    const qt = headerData.lastIncidentDetails.quarter;
    switch (qt) {
      case 1:
        return "1st Quarter"
      case 2:
        return "2nd Quarter"
      case 3:
        return "3rd Quarter"
      case 4:
        return "4th Quarter"
      default:
        return qt + "th Quarter"
    }
  }

  getOver(headerData) {
    if (!headerData) return "Starting";
    if (!headerData.cricketFixtureScore) return "Starting";
    const over = headerData.cricketFixtureScore.currentOver.toString();
    if (over.endsWith('1') && !over.endsWith('11')) {
      return over + 'st over';
    }
    if (over.endsWith('2') && !over.endsWith('12')) {
      return over + 'nd over';
    }
    if (over.endsWith('3') && !over.endsWith('13')) {
      return over + 'rd over';
    }
    return over + 'th over';
  }

  getYardsString(yards) {
    if(yards <= -151) return "This would be way below his season average"
    else if(yards >= -150 && yards <= -81) return "This would be below his season average"
    else if(yards >= -80 && yards <= -31) return "This would be slightly less than his season average"
    else if(yards >= -30 && yards <= 30) return "This would be around his season average"
    else if(yards >= 31 && yards <= 80) return "This would be slightly above his season average"
    else if(yards >= 81 && yards <= 150) return "This would be higher than his season average"
    else if(yards >= 151) return "This would be well above his season average"
    else return "Adjust the slider to select your yards"
  }

  handleNoScrollPopups(){
    const bodyTag = document.getElementsByTagName('body')[0]
    if(bodyTag){
      bodyTag.classList.toggle('no_scroll')
    }
  }

  /**
    * Get ordinal suffix for a number
   */
  getOrdinalSuffix(num) {
    if (num > 3 && num < 21) return 'th';
    switch (num % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  }

  getNames(name) {
    return this.abbreviationService.abbreviateSgsTeamName(name) 
    || this.abbreviationService.abbreviateSgsBasketballTeamName(name)
    || this.abbreviationService.abbreviateSNLTeamName(name)
  }

  isCurrentTeamHome(battingTeam: string, fixtureSummary: any) {
    return fixtureSummary.homeName === battingTeam
  }

}
