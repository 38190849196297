export interface Theme {
  name: string;
  properties: any;
}

export const defaultTheme: Theme = {
  name: "affiliateFree",
  properties: {
    "--default-font-family": "Barlow",
    "--heading-font-family": "Barlow-SemiBold",
    "--label-font-family": "Barlow-Medium",
    "--heavey-font-family": "Barlow-SemiBold",
    "--condensed-font-family": "BarlowCondensed-Medium",
    "--condensed-normal-font-family": "BarlowCondensed",
    "--default-font-size": "14px",
    "--logoSrc": "",
    "--logo-width": "65%",
    "--logo-height": "38px",
    "--logo-height-small": "38px",

    "--default-page-width": "375px",
    "--default-padding": "20px",

    "--background-default": "#051629",
    "--background-secondary": "#22b07d",
    "--background-tertiary": "1px solid #253a4f",
    "--background-light": "#FFFFFF",
    "--background-light-grey": "#f5f6f8",
    "--background-mygames-buffer": "#051629",
    "--survivor-picker-text": "#051629",

    "--foreground-default": "#FFFFFF",
    "--foreground-secondary": "#2a3e53",
    "--foreground-tertiary": "#566473",
    "--foreground-light": "#41474D",
    "--foreground-dark": "#051629",
    "--foreground-highlight": "#f63e73",
    "--foreground-grey" : "#57606a",
    "--foreground-dark-grey" : "#4d5268",
    "--color-on-dark-bg": "#333333",
    "--foreground-dark-barlow": "#171717",

    "--error-default": "#EF3E36",
    "--error-dark": "#800600",
    "--error-light": "#FFCECC",

    "--top-drop-shadow": "0 -8px 7px 0 rgba(0, 0, 0, 0.5)",
    "--button-primary-color": "#FFF",
    "--button-inverted": "#000",
    "--button-inverted-border": "#000",
    "--button-secondary-color": "#FFFFFF",
    "--button-border-color": "#22b07d",
    "--input-border-color" : "#DDDDDD",
    "--survivor-border-color" : "#376aed",
    "--foreground-footer": "#7c91a6",
    "--link-color": "#22b07d",
    "--item-selected": "#ff9900",
    "--picker-selected": "#f19d38",
    "--picker-selected-color": "#000",
    "--sgs-picker-selected": "#2A373E",
    "--sgs-picker-selected-color": "#fff",
    "--foreground-tab": "#2a3e53",
    "--partner-color": "#ff4500",

    "--contrast-color": "#22b07d",
    "--affiliate-header-color": "#051629",

    "--button-consent-color": "#FF9900",
    "--button-consent-text-color": "#FFF",


    "--header-wallet-color": "#22b07d",

    "--horse-picker-bar": "#D11F25",

    "--horse-leaderboard-tab": "#D11F25",
    "--horse-inplay-header-font-size": "24px",
    "--horse-inplay-header-line-height": "29px",

    "--header-menu-bg": "#051629",
    "--golf-leaderboard-tabs-background": "#FF9900",
    
    "--horse-fib-picker-tab-background": "#D9D9D9",
    "--horse-fib-picker-tab-text": "#1F375B",
    "--horse-fib-picker-tab-background-selected": "#1493FF",
    "--horse-fib-picker-tab-background-branch-selected": "#1493FF",
    "--horse-fib-picker-tab-background-last": "#A6CEF3",

    "--horse-fib-picker-slider-bg": "#D9D9D9",
    "--horse-fib-picker-slider-bg-active": "#3454D1",
    "--horse-fib-picker-slider-bg-focus": "#2196F3",
    "--horse-fib-picker-slider-text": "#484848",
    "--horse-fib-picker-slider-text-active": "#fff",
    
    "--horse-fib-picker-horse-selected-bg": "#000",
    "--horse-fib-picker-horse-selected-pts": "#3454D1",
    "--horse-fib-picker-horse-selected-pts-color": "#fff",

    "--horse-fib-inplay-ticket-highlight": "#F99F4A",

    "--header-padding": "16px 10px",
    "--header-logo-margin": "0px",
    "--header-align-items": "center",
    "--header-menu-margin": "5px 0 0 0",
    "--header-wallet-padding": "0 0 0 10px",
    "--button-primary-bg": "#3454D1",
    "--button-primary-text": "#FFF",
    "--action-text-transform": "none",
    
    "--br-2": "2px",
    "--br-4": "4px",
    "--br-6": "6px",
    "--br-8": "8px",
    "--br-10": "10px",
    "--br-16": "16px",
    "--br-20": "20px",

    "--text-color-fg": "#fff",
    "--filter-highlight-bg": "#22B07D",
    "--filter-text-color": "#fff",
    "--filter-text-active-color": "#fff",
    "--filter-bg": "#253137",
    "--lobby-text-color": "#fff",
    "--lobby-help-color": "#fff",
    "--lobby-help-bg": "#045DE9",
    "--light-contrast-bg": "#f4f4f4",
    "--condensed-text-font-size": "16px",
    "--condensed-text-font-letter-spacing": "normal",
    "--heading-font-weight": "normal",

    "--lobby-font-family": "Barlow",


    "--br-diff-2": "2px",
    "--br-diff-4": "4px",
    "--br-diff-6": "6px",
    "--br-diff-8": "8px",
    "--br-diff-10": "10px",
    "--br-diff-20": "20px",

    "--promo-carousel-arrow-fill": "#fff",
    
  }
}

export const fitzdares: Theme = {
  name: "fitzdares",
  properties: {

    "--default-font-family": "Barlow",
    "--heading-font-family": "Barlow-SemiBold",
    "--label-font-family": "Barlow-Medium",
    "--heavey-font-family": "Barlow-SemiBold",
    "--condensed-font-family": "BarlowCondensed-Medium",
    "--condensed-normal-font-family": "BarlowCondensed",
    "--default-font-size": "14px",
    "--logoSrc": "url(../../assets/images/fitzdares-logo-white.svg)",
    "--logo-width": "65%",
    "--logo-header-width": "170px",
    "--logo-height": "38px",
    "--logo-height-small": "38px",

    "--default-page-width": "375px",
    "--default-padding": "20px",

    "--background-default": "#051629",
    "--background-secondary": "#22b07d",
    "--background-tertiary": "1px solid #253a4f",
    "--background-light": "#FFFFFF",
    "--background-light-grey": "#f5f6f8",
    "--background-mygames-buffer": "#051629",
    "--survivor-picker-text": "#051629",

    "--foreground-default": "#FFFFFF",
    "--foreground-secondary": "#2a3e53",
    "--foreground-tertiary": "#566473",
    "--foreground-light": "#41474D",
    "--foreground-dark": "#051629",
    "--foreground-highlight": "#f63e73",
    "--foreground-grey" : "#57606a",
    "--foreground-dark-grey" : "#4d5268",
    "--color-on-dark-bg": "#333333",
    "--foreground-dark-barlow": "#171717",

    "--error-default": "#EF3E36",
    "--error-dark": "#800600",
    "--error-light": "#FFCECC",

    "--top-drop-shadow": "0 -8px 7px 0 rgba(0, 0, 0, 0.5)",
    "--button-primary-color": "#FFF",
    "--button-inverted": "#000",
    "--button-inverted-border": "#000",
    "--button-secondary-color": "#FFFFFF",
    "--button-border-color": "#22b07d",
    "--input-border-color" : "#DDDDDD",
    "--survivor-border-color" : "#376aed",
    "--foreground-footer": "#7c91a6",
    "--link-color": "#22b07d",
    "--item-selected": "#ff9900",
    "--picker-selected": "#f19d38",
    "--picker-selected-color": "#000",
    "--sgs-picker-selected": "#2A373E",
    "--sgs-picker-selected-color": "#fff",
    "--foreground-tab": "#2a3e53",
    "--partner-color": "#ff4500",

    "--contrast-color": "#22b07d",
    "--affiliate-header-color": "#051629",

    "--button-consent-color": "#FF9900",
    "--button-consent-text-color": "#FFF",


    "--header-wallet-color": "#22b07d",

    "--horse-picker-bar": "#22b07d",

    "--horse-leaderboard-tab": "#D11F25",
    "--horse-inplay-header-font-size": "24px",
    "--horse-inplay-header-line-height": "29px",

    "--header-menu-bg": "#051629",
    "--golf-leaderboard-tabs-background": "#FF9900",
    
    "--horse-fib-picker-tab-background": "#D9D9D9",
    "--horse-fib-picker-tab-text": "#1F375B",
    "--horse-fib-picker-tab-background-selected": "#1493FF",
    "--horse-fib-picker-tab-background-branch-selected": "#1493FF",
    "--horse-fib-picker-tab-background-last": "#A6CEF3",

    "--horse-fib-picker-slider-bg": "#D9D9D9",
    "--horse-fib-picker-slider-bg-active": "#3454D1",
    "--horse-fib-picker-slider-bg-focus": "#2196F3",
    "--horse-fib-picker-slider-text": "#484848",
    "--horse-fib-picker-slider-text-active": "#fff",
    
    "--horse-fib-picker-horse-selected-bg": "#000",
    "--horse-fib-picker-horse-selected-pts": "#3454D1",
    "--horse-fib-picker-horse-selected-pts-color": "#fff",

    "--horse-fib-inplay-ticket-highlight": "#F99F4A",

    "--header-padding": "16px 10px",
    "--header-logo-margin": "0px",
    "--header-align-items": "center",
    "--header-menu-margin": "5px 0 0 0",
    "--header-wallet-padding": "0 0 0 10px",
    "--button-primary-bg": "#3454D1",
    "--button-primary-text": "#FFF",
    "--action-text-transform": "none",

    "--br-2": "2px",
    "--br-4": "4px",
    "--br-6": "6px",
    "--br-8": "8px",
    "--br-10": "10px",
    "--br-20": "20px",

    "--text-color-fg": "#fff",
    "--filter-highlight-bg": "#22B07D",
    "--filter-text-color": "#fff",
    "--filter-text-active-color": "#fff",
    "--filter-bg": "#253137",
    "--lobby-text-color": "#fff",
    "--lobby-help-color": "#fff",
    "--lobby-help-bg": "#045DE9",
    "--light-contrast-bg": "#f4f4f4",
    "--condensed-text-font-size": "16px",
    "--condensed-text-font-letter-spacing": "normal",
    "--heading-font-weight": "normal",

    "--lobby-font-family": "Barlow",


    "--br-diff-2": "2px",
    "--br-diff-4": "4px",
    "--br-diff-6": "6px",
    "--br-diff-8": "8px",
    "--br-diff-10": "10px",
    "--br-diff-20": "20px",

    "--promo-carousel-arrow-fill": "#fff",
    
  }
};

export const talksport: Theme = {
  name: "talksport",
  properties: {
    "--default-font-family": "Barlow",
    "--heading-font-family": "Barlow-SemiBold",
    "--label-font-family": "Barlow-Medium",
    "--heavey-font-family": "Barlow-SemiBold",
    "--condensed-font-family": "BarlowCondensed-Medium",
    "--condensed-normal-font-family": "BarlowCondensed",
    "--default-font-size": "14px",
    "--logoSrc": "url(../../assets/images/talksport-games-newlogo.svg)",
    "--logo-width": "65%",
    "--logo-header-width": "170px",
    "--logo-height": "25px",
    "--logo-height-small": "25px",

    "--default-page-width": "375px",
    "--default-padding": "20px",

    "--background-default": "#051629",
    "--background-secondary": "#FFFE03",
    "--background-tertiary": "1px solid #464646",
    "--background-light": "#FFFFFF",
    "--background-light-grey": "#f5f6f8",
    "--background-mygames-buffer": "#051629",
    "--survivor-picker-text": "#051629",

    "--foreground-default": "#FFFFFF",
    "--foreground-secondary": "#051629",
    "--foreground-tertiary": "#566473",
    "--foreground-light": "#FFFFFF",
    "--foreground-dark": "black",
    "--foreground-highlight": "#f63e73",
    "--foreground-grey" : "#57606a",
    "--foreground-dark-grey" : "#4d5268",
    "--foreground-tab": "#2a3e53",
    "--color-on-dark-bg": "#333333",
    "--foreground-dark-barlow": "#171717",

    "--error-default": "#EF3E36",
    "--error-dark": "#800600",
    "--error-light": "#FFCECC",

    "--top-drop-shadow": "0 -8px 7px 0 rgba(0, 0, 0, 0.5)",
    "--button-primary-color": "#000000",
    "--button-inverted": "#000000",
    "--button-inverted-border": "#000000",
    "--button-secondary-color": "#fff",
    "--button-border-color": "#000000",
    "--input-border-color" : "#DDDDDD",
    "--survivor-border-color" : "#376aed",
    "--foreground-footer": "#7c91a6",
    "--link-color": "#805ce4",
    "--item-selected": "#ff9900",
    "--picker-selected": "#f19d38",
    "--picker-selected-color": "#000",
    "--sgs-picker-selected": "#2A373E",
    "--sgs-picker-selected-color": "#fff",
    "--partner-color": "#E10A0A",

    "--contrast-color": "#000000",
    "--affiliate-header-color": "#051629",

    "--button-consent-color": "#FF9900",
    "--button-consent-text-color": "#000",


    "--header-wallet-color": "#22b07d",

    "--horse-picker-bar": "#FFBB50",

    "--horse-leaderboard-tab": "#D11F25",
    "--horse-inplay-header-font-size": "24px",
    "--horse-inplay-header-line-height": "29px",

    "--header-menu-bg": "#051629",
    "--golf-leaderboard-tabs-background": "#FF9900",
    
    "--horse-fib-picker-tab-background": "#D9D9D9",
    "--horse-fib-picker-tab-text": "#1F375B",
    "--horse-fib-picker-tab-background-selected": "#1493FF",
    "--horse-fib-picker-tab-background-branch-selected": "#1493FF",
    "--horse-fib-picker-tab-background-last": "#A6CEF3",

    "--horse-fib-picker-slider-bg": "#D9D9D9",
    "--horse-fib-picker-slider-bg-active": "#3454D1",
    "--horse-fib-picker-slider-bg-focus": "#2196F3",
    "--horse-fib-picker-slider-text": "#484848",
    "--horse-fib-picker-slider-text-active": "#fff",
    
    "--horse-fib-picker-horse-selected-bg": "#000",
    "--horse-fib-picker-horse-selected-pts": "#3454D1",
    "--horse-fib-picker-horse-selected-pts-color": "#fff",

    "--horse-fib-inplay-ticket-highlight": "#F99F4A",

    "--header-padding": "16px 10px",
    "--header-logo-margin": "0px",
    "--header-align-items": "center",
    "--header-menu-margin": "5px 0 0 0",
    "--header-wallet-padding": "0 0 0 10px",
    "--button-primary-bg": "#3454D1",
    "--button-primary-text": "#FFF",
    "--action-text-transform": "none",

    "--br-2": "2px",
    "--br-4": "4px",
    "--br-6": "6px",
    "--br-8": "8px",
    "--br-10": "10px",
    "--br-20": "20px",

    "--text-color-fg": "#fff",
    "--filter-highlight-bg": "#22B07D",
    "--filter-text-color": "#fff",
    "--filter-text-active-color": "#fff",
    "--filter-bg": "#253137",
    "--lobby-text-color": "#fff",
    "--lobby-help-color": "#fff",
    "--lobby-help-bg": "#045DE9",
    "--light-contrast-bg": "#f4f4f4",
    "--condensed-text-font-size": "16px",
    "--condensed-text-font-letter-spacing": "normal",
    "--heading-font-weight": "normal",

    "--lobby-font-family": "Barlow",


    "--br-diff-2": "2px",
    "--br-diff-4": "4px",
    "--br-diff-6": "6px",
    "--br-diff-8": "8px",
    "--br-diff-10": "10px",
    "--br-diff-20": "20px",

    "--promo-carousel-arrow-fill": "#fff",
    
  }
};

export const dreamteam: Theme = {
  name: "dreamteam",
  properties: {
    "--default-font-family": "Barlow",
    "--heading-font-family": "Barlow-SemiBold",
    "--label-font-family": "Barlow-Medium",
    "--heavey-font-family": "Barlow-SemiBold",
    "--condensed-font-family": "BarlowCondensed-Medium",
    "--condensed-normal-font-family": "BarlowCondensed",
    "--default-font-size": "14px",
    "--logoSrc": "url(../../assets/images/DRT-dream-team-extra-games-main.png)",
    "--logo-width": "65%",
    "--logo-header-width": "200px",
    "--logo-height": "18px",
    "--logo-height-small": "19px",

    "--default-page-width": "375px",
    "--default-padding": "20px",

    "--background-default": "#051629",
    "--background-secondary": "#EC1D25",
    "--background-tertiary": "1px solid #e4e8ed",
    "--background-light": "#FFFFFF",
    "--background-light-grey": "#f5f6f8",
    "--background-mygames-buffer": "#051629",
    "--survivor-picker-text": "#051629",

    "--foreground-default": "#FFFFFF",
    "--foreground-secondary": "#051629",
    "--foreground-tertiary": "#566473",
    "--foreground-light": "#FFFFFF",
    "--foreground-dark": "black",
    "--foreground-highlight": "#f63e73",
    "--foreground-grey" : "#57606a",
    "--foreground-dark-grey" : "#4d5268",
    "--foreground-tab": "#2a3e53",
    "--color-on-dark-bg": "#FFFFFF",
    "--foreground-dark-barlow": "#171717",

    "--error-default": "#EF3E36",
    "--error-dark": "#800600",
    "--error-light": "#FFCECC",

    "--top-drop-shadow": "0 -8px 7px 0 rgba(0, 0, 0, 0.5)",
    "--button-primary-color": "#FFF",
    "--button-inverted": "#000",
    "--button-inverted-border": "#000",
    "--button-secondary-color": "#ffffff",
    "--button-border-color": "#EC1D25",
    "--input-border-color" : "#DDDDDD",
    "--survivor-border-color" : "#376aed",
    "--foreground-footer": "#e4e8ed",
    "--link-color": "#286A45",
    "--item-selected": "#009070",
    "--picker-selected": "#009070",
    "--picker-selected-color": "#FFF",
    "--sgs-picker-selected": "#2A373E",
    "--sgs-picker-selected-color": "#fff",
    "--partner-color": "#E10A0A",

    "--contrast-color": "#EC1D25",
    "--affiliate-header-color": "#BA0D13",

    "--button-consent-color": "#009070",
    "--button-consent-text-color": "#FFF",


    "--header-wallet-color": "#22b07d",

    "--horse-picker-bar": "#D11F25",

    "--horse-leaderboard-tab": "#D11F25",
    "--horse-inplay-header-font-size": "24px",
    "--horse-inplay-header-line-height": "29px",

    "--header-menu-bg": "#051629",
    "--golf-leaderboard-tabs-background": "#FF9900",
    
    "--horse-fib-picker-tab-background": "#D9D9D9",
    "--horse-fib-picker-tab-text": "#1F375B",
    "--horse-fib-picker-tab-background-selected": "#1493FF",
    "--horse-fib-picker-tab-background-branch-selected": "#1493FF",
    "--horse-fib-picker-tab-background-last": "#A6CEF3",

    "--horse-fib-picker-slider-bg": "#D9D9D9",
    "--horse-fib-picker-slider-bg-active": "#3454D1",
    "--horse-fib-picker-slider-bg-focus": "#2196F3",
    "--horse-fib-picker-slider-text": "#484848",
    "--horse-fib-picker-slider-text-active": "#fff",
    
    "--horse-fib-picker-horse-selected-bg": "#000",
    "--horse-fib-picker-horse-selected-pts": "#3454D1",
    "--horse-fib-picker-horse-selected-pts-color": "#fff",

    "--horse-fib-inplay-ticket-highlight": "#F99F4A",

    "--header-padding": "16px 10px",
    "--header-logo-margin": "0px",
    "--header-align-items": "center",
    "--header-menu-margin": "5px 0 0 0",
    "--header-wallet-padding": "0 0 0 10px",
    "--button-primary-bg": "#3454D1",
    "--button-primary-text": "#FFF",
    "--action-text-transform": "none",

    "--br-2": "2px",
    "--br-4": "4px",
    "--br-6": "6px",
    "--br-8": "8px",
    "--br-10": "10px",
    "--br-20": "20px",

    "--text-color-fg": "#fff",
    "--filter-highlight-bg": "#22B07D",
    "--filter-text-color": "#fff",
    "--filter-text-active-color": "#fff",
    "--filter-bg": "#253137",
    "--lobby-text-color": "#fff",
    "--lobby-help-color": "#fff",
    "--lobby-help-bg": "#045DE9",
    "--light-contrast-bg": "#f4f4f4",
    "--condensed-text-font-size": "16px",
    "--condensed-text-font-letter-spacing": "normal",
    "--heading-font-weight": "normal",

    "--lobby-font-family": "Barlow",


    "--br-diff-2": "2px",
    "--br-diff-4": "4px",
    "--br-diff-6": "6px",
    "--br-diff-8": "8px",
    "--br-diff-10": "10px",
    "--br-diff-20": "20px",

    "--promo-carousel-arrow-fill": "#fff",
    
  }
};

export const rockthegoat: Theme  = {
  name: "rockthegoat",
  properties: {
    "--default-font-family": "Barlow",
    "--heading-font-family": "Barlow-SemiBold",
    "--label-font-family": "Barlow-Medium",
    "--heavey-font-family": "Barlow-SemiBold",
    "--condensed-font-family": "BarlowCondensed-Medium",
    "--condensed-normal-font-family": "BarlowCondensed",
    "--default-font-size": "14px",
    "--logoSrc": "url(../../assets/images/rtg_logo.svg)",
    "--logo-width": "65%",
    "--logo-header-width": "170px",
    "--logo-height": "38px",
    "--logo-height-small": "38px",

    "--default-page-width": "375px",
    "--default-padding": "20px",

    "--background-default": "#191F34",
    "--background-secondary": "#72B791",
    "--background-tertiary": "1px solid #464646",
    "--background-light": "#FFFFFF",
    "--background-light-grey": "#f5f6f8",
    "--background-mygames-buffer": "#191F34",
    "--survivor-picker-text": "#191F34",

    "--foreground-default": "#FFFFFF",
    "--foreground-secondary": "#191F34",
    "--foreground-tertiary": "#566473",
    "--foreground-light": "#FFFFFF",
    "--foreground-dark": "black",
    "--foreground-highlight": "#f63e73",
    "--foreground-grey" : "#57606a",
    "--foreground-dark-grey" : "#4d5268",
    "--foreground-tab": "#2a3e53",
    "--color-on-dark-bg": "#333333",
    "--foreground-dark-barlow": "#171717",

    "--error-default": "#EF3E36",
    "--error-dark": "#800600",
    "--error-light": "#FFCECC",

    "--top-drop-shadow": "0 -8px 7px 0 rgba(0, 0, 0, 0.5)",
    "--button-primary-color": "#191F34",
    "--button-inverted": "#000",
    "--button-inverted-border": "#545454",
    "--button-secondary-color": "#FFF",
    "--button-border-color": "#191F34",
    "--input-border-color" : "#DDDDDD",
    "--survivor-border-color" : "#376aed",
    "--foreground-footer": "#7c91a6",
    "--link-color": "#F0A114",
    "--item-selected": "#EDC465",
    "--picker-selected": "#EDC465",
    "--picker-selected-color": "#000",
    "--sgs-picker-selected": "#2A373E",
    "--sgs-picker-selected-color": "#fff",
    "--partner-color": "#E10A0A",

    "--contrast-color": "#000000",
    "--affiliate-header-color": "#191F34",

    "--button-consent-color": "#EDC465",
    "--button-consent-text-color": "#191F34",


    "--header-wallet-color": "#22b07d",

    "--horse-picker-bar": "#22b07d",

    "--horse-leaderboard-tab": "#D11F25",
    "--horse-inplay-header-font-size": "24px",
    "--horse-inplay-header-line-height": "29px",

    "--header-menu-bg": "#191F34",
    "--golf-leaderboard-tabs-background": "#FF9900",
    
    "--horse-fib-picker-tab-background": "#D9D9D9",
    "--horse-fib-picker-tab-text": "#1F375B",
    "--horse-fib-picker-tab-background-selected": "#1493FF",
    "--horse-fib-picker-tab-background-branch-selected": "#1493FF",
    "--horse-fib-picker-tab-background-last": "#A6CEF3",

    "--horse-fib-picker-slider-bg": "#D9D9D9",
    "--horse-fib-picker-slider-bg-active": "#3454D1",
    "--horse-fib-picker-slider-bg-focus": "#2196F3",
    "--horse-fib-picker-slider-text": "#484848",
    "--horse-fib-picker-slider-text-active": "#fff",
    
    "--horse-fib-picker-horse-selected-bg": "#000",
    "--horse-fib-picker-horse-selected-pts": "#3454D1",
    "--horse-fib-picker-horse-selected-pts-color": "#fff",

    "--horse-fib-inplay-ticket-highlight": "#F99F4A",

    "--header-padding": "16px 10px",
    "--header-logo-margin": "0px",
    "--header-align-items": "center",
    "--header-menu-margin": "5px 0 0 0",
    "--header-wallet-padding": "0 0 0 10px",
    "--button-primary-bg": "#3454D1",
    "--button-primary-text": "#FFF",
    "--action-text-transform": "none",

    "--br-2": "2px",
    "--br-4": "4px",
    "--br-6": "6px",
    "--br-8": "8px",
    "--br-10": "10px",
    "--br-20": "20px",

    "--text-color-fg": "#fff",
    "--filter-highlight-bg": "#22B07D",
    "--filter-text-color": "#fff",
    "--filter-text-active-color": "#fff",
    "--filter-bg": "#253137",
    "--lobby-text-color": "#fff",
    "--lobby-help-color": "#fff",
    "--lobby-help-bg": "#045DE9",
    "--light-contrast-bg": "#f4f4f4",
    "--condensed-text-font-size": "16px",
    "--condensed-text-font-letter-spacing": "normal",
    "--heading-font-weight": "normal",

    "--lobby-font-family": "Barlow",


    "--br-diff-2": "2px",
    "--br-diff-4": "4px",
    "--br-diff-6": "6px",
    "--br-diff-8": "8px",
    "--br-diff-10": "10px",
    "--br-diff-20": "20px",

    "--promo-carousel-arrow-fill": "#fff",
    
  }
}

export const racingpost: Theme  = {
  name: "racingpost",
  properties: {
    "--default-font-family": "Barlow",
    "--heading-font-family": "Barlow-SemiBold",
    "--label-font-family": "Barlow-Medium",
    "--heavey-font-family": "Barlow-SemiBold",
    "--condensed-font-family": "BarlowCondensed-Medium",
    "--condensed-normal-font-family": "BarlowCondensed",
    "--default-font-size": "14px",
    "--logoSrc": "url(../../assets/images/racingpost.svg)",
    "--logo-width": "65%",
    "--logo-header-width": "180px",
    "--logo-height": "20px",
    "--logo-height-small": "20px",

    "--default-page-width": "375px",
    "--default-padding": "20px",

    "--background-default": "#171717",
    "--background-secondary": "#D11F25",
    "--background-tertiary": "1px solid #e4e8ed",
    "--background-light": "#FFFFFF",
    "--background-light-grey": "#f5f6f8",
    "--background-mygames-buffer": "#171717",
    "--survivor-picker-text": "#171717",

    "--foreground-default": "#FFFFFF",
    "--foreground-secondary": "#051629",
    "--foreground-tertiary": "#566473",
    "--foreground-light": "#FFFFFF",
    "--foreground-dark": "black",
    "--foreground-highlight": "#f63e73",
    "--foreground-grey" : "#57606a",
    "--foreground-dark-grey" : "#4d5268",
    "--foreground-tab": "#2a3e53",
    "--color-on-dark-bg": "#FFFFFF",
    "--foreground-dark-barlow": "#171717",

    "--error-default": "#EF3E36",
    "--error-dark": "#800600",
    "--error-light": "#FFCECC",

    "--top-drop-shadow": "0 -8px 7px 0 rgba(0, 0, 0, 0.5)",
    "--button-primary-color": "#FFF",
    "--button-inverted": "#000",
    "--button-inverted-border": "#000",
    "--button-secondary-color": "#ffffff",
    "--button-border-color": "#D11F25",
    "--input-border-color" : "#DDDDDD",
    "--survivor-border-color" : "#376aed",
    "--foreground-footer": "#e4e8ed",
    "--link-color": "#286A45",
    "--item-selected": "#009070",
    "--picker-selected": "#009070",
    "--picker-selected-color": "#fff",
    "--sgs-picker-selected": "#2A373E",
    "--sgs-picker-selected-color": "#fff",
    "--partner-color": "#E10A0A",

    "--contrast-color": "#D11F25",
    "--affiliate-header-color": "#171717",

    "--button-consent-color": "#009070",
    "--button-consent-text-color": "#FFF",

    
    "--header-wallet-color": "#22b07d",

    "--horse-picker-bar": "#D11F25",

    "--header-menu-bg": "#171717",
    "--golf-leaderboard-tabs-background": "#FF9900",
    
    "--horse-fib-picker-tab-background": "#D9D9D9",
    "--horse-fib-picker-tab-text": "#1F375B",
    "--horse-fib-picker-tab-background-selected": "#1493FF",
    "--horse-fib-picker-tab-background-branch-selected": "#1493FF",
    "--horse-fib-picker-tab-background-last": "#A6CEF3",

    "--horse-fib-picker-slider-bg": "#D9D9D9",
    "--horse-fib-picker-slider-bg-active": "#3454D1",
    "--horse-fib-picker-slider-bg-focus": "#2196F3",
    "--horse-fib-picker-slider-text": "#484848",
    "--horse-fib-picker-slider-text-active": "#fff",
    
    "--horse-fib-picker-horse-selected-bg": "#000",
    "--horse-fib-picker-horse-selected-pts": "#3454D1",
    "--horse-fib-picker-horse-selected-pts-color": "#fff",

    "--horse-fib-inplay-ticket-highlight": "#F99F4A",

    "--header-padding": "16px 10px",
    "--header-logo-margin": "0px",
    "--header-align-items": "center",
    "--header-menu-margin": "5px 0 0 0",
    "--header-wallet-padding": "0 0 0 10px",
    "--button-primary-bg": "#3454D1",
    "--button-primary-text": "#FFF",
    "--action-text-transform": "none",

    "--br-2": "2px",
    "--br-4": "4px",
    "--br-6": "6px",
    "--br-8": "8px",
    "--br-10": "10px",
    "--br-20": "20px",

    "--text-color-fg": "#fff",
    "--filter-highlight-bg": "#22B07D",
    "--filter-text-color": "#fff",
    "--filter-text-active-color": "#fff",
    "--filter-bg": "#253137",
    "--lobby-text-color": "#fff",
    "--lobby-help-color": "#fff",
    "--lobby-help-bg": "#045DE9",
    "--light-contrast-bg": "#f4f4f4",
    "--condensed-text-font-size": "16px",
    "--condensed-text-font-letter-spacing": "normal",
    "--heading-font-weight": "normal",

    "--lobby-font-family": "Barlow",


    "--br-diff-2": "2px",
    "--br-diff-4": "4px",
    "--br-diff-6": "6px",
    "--br-diff-8": "8px",
    "--br-diff-10": "10px",
    "--br-diff-20": "20px",

    "--promo-carousel-arrow-fill": "#fff",
    
  } 
}

export const oddschecker: Theme  = {
  name: "oddschecker",
  properties: {
    "--default-font-family": "Barlow",
    "--heading-font-family": "Barlow-SemiBold",
    "--label-font-family": "Barlow-Medium",
    "--heavey-font-family": "Barlow-SemiBold",
    "--condensed-font-family": "BarlowCondensed-Medium",
    "--condensed-normal-font-family": "BarlowCondensed",
    "--default-font-size": "14px",
    "--logoSrc": "url(../../assets/images/oddschecker.svg)",
    "--logo-width": "65%",
    "--logo-header-width": "158px",
    "--logo-height": "22px",
    "--logo-height-small": "22px",

    "--default-page-width": "375px",
    "--default-padding": "20px",

    "--background-default": "#033954",
    "--background-secondary": "#0EDD98",
    "--background-tertiary": "1px solid #e4e8ed",
    "--background-light": "#FFFFFF",
    "--background-light-grey": "#f5f6f8",
    "--background-mygames-buffer": "#033954",
    "--survivor-picker-text": "#051629",

    "--foreground-default": "#FFFFFF",
    "--foreground-secondary": "#051629",
    "--foreground-tertiary": "#566473",
    "--foreground-light": "#FFFFFF",
    "--foreground-dark": "black",
    "--foreground-highlight": "#f63e73",
    "--foreground-grey" : "#57606a",
    "--foreground-dark-grey" : "#4d5268",
    "--foreground-tab": "#2a3e53",
    "--color-on-dark-bg": "#FFFFFF",
    "--foreground-dark-barlow": "#171717",

    "--error-default": "#EF3E36",
    "--error-dark": "#800600",
    "--error-light": "#FFCECC",

    "--top-drop-shadow": "0 -8px 7px 0 rgba(0, 0, 0, 0.5)",
    "--button-primary-color": "#000",
    "--button-inverted": "#000",
    "--button-inverted-border": "#000",
    "--button-secondary-color": "#ffffff",
    "--button-border-color": "#0EDD98",
    "--input-border-color" : "#DDDDDD",
    "--survivor-border-color" : "#376aed",
    "--foreground-footer": "#e4e8ed",
    "--link-color": "#286A45",
    "--item-selected": "#0EDD98",
    "--picker-selected": "#0EDD98",
    "--picker-selected-color": "#000",
    "--sgs-picker-selected": "#2A373E",
    "--sgs-picker-selected-color": "#fff",
    "--partner-color": "#E10A0A",

    "--contrast-color": "#0EDD98",
    "--affiliate-header-color": "#033954",

    "--button-consent-color": "#0EDD98",
    "--button-consent-text-color": "#000",

    
    "--header-wallet-color": "#22b07d",

    "--horse-picker-bar": "#033954",

    "--horse-leaderboard-tab": "#014C6B",
    "--horse-inplay-header-font-size": "24px",
    "--horse-inplay-header-line-height": "29px",

    "--header-menu-bg": "#033954",
    "--golf-leaderboard-tabs-background": "#FF9900",
    
    "--horse-fib-picker-tab-background": "#D9D9D9",
    "--horse-fib-picker-tab-text": "#1F375B",
    "--horse-fib-picker-tab-background-selected": "#1493FF",
    "--horse-fib-picker-tab-background-branch-selected": "#1493FF",
    "--horse-fib-picker-tab-background-last": "#A6CEF3",

    "--horse-fib-picker-slider-bg": "#D9D9D9",
    "--horse-fib-picker-slider-bg-active": "#3454D1",
    "--horse-fib-picker-slider-bg-focus": "#2196F3",
    "--horse-fib-picker-slider-text": "#484848",
    "--horse-fib-picker-slider-text-active": "#fff",
    
    "--horse-fib-picker-horse-selected-bg": "#000",
    "--horse-fib-picker-horse-selected-pts": "#3454D1",
    "--horse-fib-picker-horse-selected-pts-color": "#fff",

    "--horse-fib-inplay-ticket-highlight": "#F99F4A",

    "--header-padding": "16px 10px",
    "--header-logo-margin": "0px",
    "--header-align-items": "center",
    "--header-menu-margin": "5px 0 0 0",
    "--header-wallet-padding": "0 0 0 10px",
    "--button-primary-bg": "#3454D1",
    "--button-primary-text": "#FFF",
    "--action-text-transform": "none",

    "--br-2": "2px",
    "--br-4": "4px",
    "--br-6": "6px",
    "--br-8": "8px",
    "--br-10": "10px",
    "--br-20": "20px",

    "--text-color-fg": "#fff",
    "--filter-highlight-bg": "#22B07D",
    "--filter-text-color": "#fff",
    "--filter-text-active-color": "#fff",
    "--filter-bg": "#253137",
    "--lobby-text-color": "#fff",
    "--lobby-help-color": "#fff",
    "--lobby-help-bg": "#045DE9",
    "--light-contrast-bg": "#f4f4f4",
    "--condensed-text-font-size": "16px",
    "--condensed-text-font-letter-spacing": "normal",
    "--heading-font-weight": "normal",

    "--lobby-font-family": "Barlow",


    "--br-diff-2": "2px",
    "--br-diff-4": "4px",
    "--br-diff-6": "6px",
    "--br-diff-8": "8px",
    "--br-diff-10": "10px",
    "--br-diff-20": "20px",

    "--promo-carousel-arrow-fill": "#fff",
    
  } 
}

export const bolavip: Theme  = {
  name: "bolavip",
  properties: {
    "--default-font-family": "Barlow",
    "--heading-font-family": "Barlow-SemiBold",
    "--label-font-family": "Barlow-Medium",
    "--heavey-font-family": "Barlow-SemiBold",
    "--condensed-font-family": "BarlowCondensed-Medium",
    "--condensed-normal-font-family": "BarlowCondensed",
    "--default-font-size": "14px",
    "--logoSrc": "url(../../assets/images/bolavip.svg)",
    "--logo-width": "65%",
    "--logo-header-width": "180px",
    "--logo-height": "20px",
    "--logo-height-small": "20px",

    "--default-page-width": "375px",
    "--default-padding": "20px",

    "--background-default": "#171717",
    "--background-secondary": "#CB0023",
    "--background-tertiary": "1px solid #e4e8ed",
    "--background-light": "#FFFFFF",
    "--background-light-grey": "#f5f6f8",
    "--background-mygames-buffer": "#171717",
    "--survivor-picker-text": "#171717",

    "--foreground-default": "#FFFFFF",
    "--foreground-secondary": "#051629",
    "--foreground-tertiary": "#566473",
    "--foreground-light": "#FFFFFF",
    "--foreground-dark": "black",
    "--foreground-highlight": "#f63e73",
    "--foreground-grey" : "#57606a",
    "--foreground-dark-grey" : "#4d5268",
    "--foreground-tab": "#2a3e53",
    "--color-on-dark-bg": "#FFFFFF",
    "--foreground-dark-barlow": "#171717",

    "--error-default": "#EF3E36",
    "--error-dark": "#800600",
    "--error-light": "#FFCECC",

    "--top-drop-shadow": "0 -8px 7px 0 rgba(0, 0, 0, 0.5)",
    "--button-primary-color": "#FFF",
    "--button-inverted": "#000",
    "--button-inverted-border": "#000",
    "--button-secondary-color": "#ffffff",
    "--button-border-color": "#CB0023",
    "--input-border-color" : "#DDDDDD",
    "--survivor-border-color" : "#376aed",
    "--foreground-footer": "#e4e8ed",
    "--link-color": "#286A45",
    "--item-selected": "#009070",
    "--picker-selected": "#009070",
    "--picker-selected-color": "#fff",
    "--sgs-picker-selected": "#2A373E",
    "--sgs-picker-selected-color": "#fff",
    "--partner-color": "#E10A0A",

    "--contrast-color": "#CB0023",
    "--affiliate-header-color": "#171717",

    "--button-consent-color": "#009070",
    "--button-consent-text-color": "#FFF",

    
    "--header-wallet-color": "#22b07d",

    "--horse-picker-bar": "#CB0023",

    "--header-menu-bg": "#171717",
    "--golf-leaderboard-tabs-background": "#FF9900",
    
    "--horse-fib-picker-tab-background": "#D9D9D9",
    "--horse-fib-picker-tab-text": "#1F375B",
    "--horse-fib-picker-tab-background-selected": "#1493FF",
    "--horse-fib-picker-tab-background-branch-selected": "#1493FF",
    "--horse-fib-picker-tab-background-last": "#A6CEF3",

    "--horse-fib-picker-slider-bg": "#D9D9D9",
    "--horse-fib-picker-slider-bg-active": "#3454D1",
    "--horse-fib-picker-slider-bg-focus": "#2196F3",
    "--horse-fib-picker-slider-text": "#484848",
    "--horse-fib-picker-slider-text-active": "#fff",
    
    "--horse-fib-picker-horse-selected-bg": "#000",
    "--horse-fib-picker-horse-selected-pts": "#3454D1",
    "--horse-fib-picker-horse-selected-pts-color": "#fff",

    "--horse-fib-inplay-ticket-highlight": "#F99F4A",

    "--header-padding": "16px 10px",
    "--header-logo-margin": "0px",
    "--header-align-items": "center",
    "--header-menu-margin": "5px 0 0 0",
    "--header-wallet-padding": "0 0 0 10px",
    "--button-primary-bg": "#3454D1",
    "--button-primary-text": "#FFF",
    "--action-text-transform": "none",

    "--br-2": "2px",
    "--br-4": "4px",
    "--br-6": "6px",
    "--br-8": "8px",
    "--br-10": "10px",
    "--br-20": "20px",

    "--text-color-fg": "#fff",
    "--filter-highlight-bg": "#22B07D",
    "--filter-text-color": "#fff",
    "--filter-text-active-color": "#fff",
    "--filter-bg": "#253137",
    "--lobby-text-color": "#fff",
    "--lobby-help-color": "#fff",
    "--lobby-help-bg": "#045DE9",
    "--light-contrast-bg": "#f4f4f4",
    "--condensed-text-font-size": "16px",
    "--condensed-text-font-letter-spacing": "normal",
    "--heading-font-weight": "normal",

    "--lobby-font-family": "Barlow",


    "--br-diff-2": "2px",
    "--br-diff-4": "4px",
    "--br-diff-6": "6px",
    "--br-diff-8": "8px",
    "--br-diff-10": "10px",
    "--br-diff-20": "20px",

    "--promo-carousel-arrow-fill": "#fff",
    
  } 
}

export const whoscored: Theme  = {
  name: "whoscored",
  properties: {
    "--default-font-family": "Barlow",
    "--heading-font-family": "Barlow-SemiBold",
    "--label-font-family": "Barlow-Medium",
    "--heavey-font-family": "Barlow-SemiBold",
    "--condensed-font-family": "BarlowCondensed-Medium",
    "--condensed-normal-font-family": "BarlowCondensed",
    "--default-font-size": "14px",
    "--logoSrc": "url(../../assets/images/ws_logo.svg)",
    "--logo-width": "65%",
    "--logo-header-width": "180px",
    "--logo-height": "40px",
    "--logo-height-small": "40px",

    "--default-page-width": "375px",
    "--default-padding": "20px",

    "--background-default": "#282424",
    "--background-secondary": "#89DC1C",
    "--background-tertiary": "1px solid #e4e8ed",
    "--background-light": "#FFFFFF",
    "--background-light-grey": "#f5f6f8",
    "--background-mygames-buffer": "#282424",
    "--survivor-picker-text": "#051629",

    "--foreground-default": "#FFFFFF",
    "--foreground-secondary": "#051629",
    "--foreground-tertiary": "#566473",
    "--foreground-light": "#FFFFFF",
    "--foreground-dark": "black",
    "--foreground-highlight": "#f63e73",
    "--foreground-grey" : "#57606a",
    "--foreground-dark-grey" : "#4d5268",
    "--foreground-tab": "#2a3e53",
    "--color-on-dark-bg": "#FFFFFF",
    "--foreground-dark-barlow": "#171717",

    "--error-default": "#EF3E36",
    "--error-dark": "#800600",
    "--error-light": "#FFCECC",

    "--top-drop-shadow": "0 -8px 7px 0 rgba(0, 0, 0, 0.5)",
    "--button-primary-color": "#000000",
    "--button-inverted": "#000",
    "--button-inverted-border": "#000",
    "--button-secondary-color": "#ffffff",
    "--button-border-color": "#CB0023",
    "--input-border-color" : "#DDDDDD",
    "--survivor-border-color" : "#376aed",
    "--foreground-footer": "#e4e8ed",
    "--link-color": "#286A45",
    "--item-selected": "#3AA3D1",
    "--picker-selected": "#3AA3D1",
    "--picker-selected-color": "#FFF",
    "--sgs-picker-selected": "#2A373E",
    "--sgs-picker-selected-color": "#fff",
    "--partner-color": "#E10A0A",

    "--contrast-color": "#CB0023",
    "--affiliate-header-color": "#171717",

    "--button-consent-color": "#3AA3D1",
    "--button-consent-text-color": "#FFF",
    
    "--header-wallet-color": "#22b07d",

    "--horse-picker-bar": "#CB0023",

    "--header-menu-bg": "#262626",
    "--golf-leaderboard-tabs-background": "#FF9900",
    
    "--horse-fib-picker-tab-background": "#D9D9D9",
    "--horse-fib-picker-tab-text": "#1F375B",
    "--horse-fib-picker-tab-background-selected": "#1493FF",
    "--horse-fib-picker-tab-background-branch-selected": "#1493FF",
    "--horse-fib-picker-tab-background-last": "#A6CEF3",

    "--horse-fib-picker-slider-bg": "#D9D9D9",
    "--horse-fib-picker-slider-bg-active": "#3454D1",
    "--horse-fib-picker-slider-bg-focus": "#2196F3",
    "--horse-fib-picker-slider-text": "#484848",
    "--horse-fib-picker-slider-text-active": "#fff",
    
    "--horse-fib-picker-horse-selected-bg": "#000",
    "--horse-fib-picker-horse-selected-pts": "#3454D1",
    "--horse-fib-picker-horse-selected-pts-color": "#fff",

    "--horse-fib-inplay-ticket-highlight": "#F99F4A",

    "--header-padding": "16px 10px",
    "--header-logo-margin": "0px",
    "--header-align-items": "center",
    "--header-menu-margin": "5px 0 0 0",
    "--header-wallet-padding": "0 0 0 10px",
    "--button-primary-bg": "#3454D1",
    "--button-primary-text": "#FFF",
    "--action-text-transform": "none",

    "--br-2": "2px",
    "--br-4": "4px",
    "--br-6": "6px",
    "--br-8": "8px",
    "--br-10": "10px",
    "--br-20": "20px",

    "--text-color-fg": "#fff",
    "--filter-highlight-bg": "#22B07D",
    "--filter-text-color": "#fff",
    "--filter-text-active-color": "#fff",
    "--filter-bg": "#253137",
    "--lobby-text-color": "#fff",
    "--lobby-help-color": "#fff",
    "--lobby-help-bg": "#045DE9",
    "--light-contrast-bg": "#f4f4f4",
    "--condensed-text-font-size": "16px",
    "--condensed-text-font-letter-spacing": "normal",
    "--heading-font-weight": "normal",

    "--lobby-font-family": "Barlow",


    "--br-diff-2": "2px",
    "--br-diff-4": "4px",
    "--br-diff-6": "6px",
    "--br-diff-8": "8px",
    "--br-diff-10": "10px",
    "--br-diff-20": "20px",

    "--promo-carousel-arrow-fill": "#fff",
    
  } 
}

export const planetsport: Theme  = {
  name: "planetsport",
  properties: {
    "--default-font-family": "Barlow",
    "--heading-font-family": "Barlow-SemiBold",
    "--label-font-family": "Barlow-Medium",
    "--heavey-font-family": "Barlow-SemiBold",
    "--condensed-font-family": "BarlowCondensed-Medium",
    "--condensed-normal-font-family": "BarlowCondensed",
    "--default-font-size": "14px",
    "--logoSrc": "url(../../assets/images/planetsport.svg)",
    "--logo-width": "75%",
    "--logo-header-width": "420px",
    "--logo-height": "45px",
    "--logo-height-small": "25px",

    "--default-page-width": "375px",
    "--default-padding": "20px",

    "--background-default": "#010A26",
    "--background-secondary": "#0F71F2",
    "--background-tertiary": "1px solid #e4e8ed",
    "--background-light": "#FFFFFF",
    "--background-light-grey": "#f5f6f8",
    "--background-mygames-buffer": "#010A26",
    "--survivor-picker-text": "#010A26",

    "--foreground-default": "#FFFFFF",
    "--foreground-secondary": "#051629",
    "--foreground-tertiary": "#566473",
    "--foreground-light": "#FFFFFF",
    "--foreground-dark": "black",
    "--foreground-highlight": "#f63e73",
    "--foreground-grey" : "#57606a",
    "--foreground-dark-grey" : "#4d5268",
    "--foreground-tab": "#2a3e53",
    "--color-on-dark-bg": "#FFFFFF",
    "--foreground-dark-barlow": "#171717",

    "--error-default": "#EF3E36",
    "--error-dark": "#800600",
    "--error-light": "#FFCECC",

    "--top-drop-shadow": "0 -8px 7px 0 rgba(0, 0, 0, 0.5)",
    "--button-primary-color": "#FFF",
    "--button-inverted": "#000",
    "--button-inverted-border": "#000",
    "--button-secondary-color": "#ffffff",
    "--button-border-color": "#CB0023",
    "--input-border-color" : "#DDDDDD",
    "--survivor-border-color" : "#376aed",
    "--foreground-footer": "#e4e8ed",
    "--link-color": "#286A45",
    "--item-selected": "#EC004F",
    "--picker-selected": "#EC004F",
    "--picker-selected-color": "#FFF",
    "--sgs-picker-selected": "#2A373E",
    "--sgs-picker-selected-color": "#fff",
    "--partner-color": "#E10A0A",

    "--contrast-color": "#CB0023",
    "--affiliate-header-color": "#010A26",

    "--button-consent-color": "#EC004F",
    "--button-consent-text-color": "#FFF",
    
    "--header-wallet-color": "#22b07d",

    "--horse-picker-bar": "#CB0023",

    "--header-menu-bg": "#262626",
    "--golf-leaderboard-tabs-background": "#FF9900",
    
    "--horse-fib-picker-tab-background": "#D9D9D9",
    "--horse-fib-picker-tab-text": "#1F375B",
    "--horse-fib-picker-tab-background-selected": "#1493FF",
    "--horse-fib-picker-tab-background-branch-selected": "#1493FF",
    "--horse-fib-picker-tab-background-last": "#A6CEF3",

    "--horse-fib-picker-slider-bg": "#D9D9D9",
    "--horse-fib-picker-slider-bg-active": "#3454D1",
    "--horse-fib-picker-slider-bg-focus": "#2196F3",
    "--horse-fib-picker-slider-text": "#484848",
    "--horse-fib-picker-slider-text-active": "#fff",
    
    "--horse-fib-picker-horse-selected-bg": "#000",
    "--horse-fib-picker-horse-selected-pts": "#3454D1",
    "--horse-fib-picker-horse-selected-pts-color": "#fff",

    "--horse-fib-inplay-ticket-highlight": "#F99F4A",

    "--header-padding": "16px 10px",
    "--header-logo-margin": "0px",
    "--header-align-items": "center",
    "--header-menu-margin": "5px 0 0 0",
    "--header-wallet-padding": "0 0 0 10px",
    "--button-primary-bg": "#3454D1",
    "--button-primary-text": "#FFF",
    "--action-text-transform": "none",

    "--br-2": "2px",
    "--br-4": "4px",
    "--br-6": "6px",
    "--br-8": "8px",
    "--br-10": "10px",
    "--br-20": "20px",

    "--text-color-fg": "#fff",
    "--filter-highlight-bg": "#22B07D",
    "--filter-text-color": "#fff",
    "--filter-text-active-color": "#fff",
    "--filter-bg": "#253137",
    "--lobby-text-color": "#fff",
    "--lobby-help-color": "#fff",
    "--lobby-help-bg": "#045DE9",
    "--light-contrast-bg": "#f4f4f4",
    "--condensed-text-font-size": "16px",
    "--condensed-text-font-letter-spacing": "normal",
    "--heading-font-weight": "normal",

    "--lobby-font-family": "Barlow",


    "--br-diff-2": "2px",
    "--br-diff-4": "4px",
    "--br-diff-6": "6px",
    "--br-diff-8": "8px",
    "--br-diff-10": "10px",
    "--br-diff-20": "20px",

    "--promo-carousel-arrow-fill": "#fff",
    
  } 
}

export const greatgoals: Theme  = {
  name: "greatgoals",
  properties: {
    "--default-font-family": "Barlow",
    "--heading-font-family": "Barlow-SemiBold",
    "--label-font-family": "Barlow-Medium",
    "--heavey-font-family": "Barlow-SemiBold",
    "--condensed-font-family": "BarlowCondensed-Medium",
    "--condensed-normal-font-family": "BarlowCondensed",
    "--default-font-size": "14px",
    "--logoSrc": "url(../../assets/images/101gg-logo.svg)",
    "--logo-width": "65%",
    "--logo-header-width": "180px",
    "--logo-height": "40px",
    "--logo-height-small": "40px",

    "--default-page-width": "375px",
    "--default-padding": "20px",

    "--background-default": "#1A1D25",
    "--background-secondary": "#FFBC0F",
    "--background-tertiary": "1px solid #e4e8ed",
    "--background-light": "#FFFFFF",
    "--background-light-grey": "#f5f6f8",
    "--background-mygames-buffer": "#1A1D25",
    "--survivor-picker-text": "#1A1D25",

    "--foreground-default": "#FFFFFF",
    "--foreground-secondary": "#051629",
    "--foreground-tertiary": "#566473",
    "--foreground-light": "#FFFFFF",
    "--foreground-dark": "black",
    "--foreground-highlight": "#f63e73",
    "--foreground-grey" : "#57606a",
    "--foreground-dark-grey" : "#4d5268",
    "--foreground-tab": "#2a3e53",
    "--color-on-dark-bg": "#FFFFFF",
    "--foreground-dark-barlow": "#171717",

    "--error-default": "#EF3E36",
    "--error-dark": "#800600",
    "--error-light": "#FFCECC",

    "--top-drop-shadow": "0 -8px 7px 0 rgba(0, 0, 0, 0.5)",
    "--button-primary-color": "#1A1D25",
    "--button-inverted": "#000",
    "--button-inverted-border": "#000",
    "--button-secondary-color": "#ffffff",
    "--button-border-color": "#CB0023",
    "--input-border-color" : "#DDDDDD",
    "--survivor-border-color" : "#376aed",
    "--foreground-footer": "#e4e8ed",
    "--link-color": "#286A45",
    "--item-selected": "#232732",
    "--picker-selected": "#232732",
    "--picker-selected-color": "#FFF",
    "--sgs-picker-selected": "#2A373E",
    "--sgs-picker-selected-color": "#fff",
    "--partner-color": "#E10A0A",

    "--contrast-color": "#CB0023",
    "--affiliate-header-color": "#1A1D25",

    "--button-consent-color": "#232732",
    "--button-consent-text-color": "#FFF",
    
    "--header-wallet-color": "#22b07d",

    "--horse-picker-bar": "#CB0023",

    "--header-menu-bg": "#262626",
    "--golf-leaderboard-tabs-background": "#FF9900",
    
    "--horse-fib-picker-tab-background": "#D9D9D9",
    "--horse-fib-picker-tab-text": "#1F375B",
    "--horse-fib-picker-tab-background-selected": "#1493FF",
    "--horse-fib-picker-tab-background-branch-selected": "#1493FF",
    "--horse-fib-picker-tab-background-last": "#A6CEF3",

    "--horse-fib-picker-slider-bg": "#D9D9D9",
    "--horse-fib-picker-slider-bg-active": "#3454D1",
    "--horse-fib-picker-slider-bg-focus": "#2196F3",
    "--horse-fib-picker-slider-text": "#484848",
    "--horse-fib-picker-slider-text-active": "#fff",
    
    "--horse-fib-picker-horse-selected-bg": "#000",
    "--horse-fib-picker-horse-selected-pts": "#3454D1",
    "--horse-fib-picker-horse-selected-pts-color": "#fff",

    "--horse-fib-inplay-ticket-highlight": "#F99F4A",

    "--header-padding": "16px 10px",
    "--header-logo-margin": "0px",
    "--header-align-items": "center",
    "--header-menu-margin": "5px 0 0 0",
    "--header-wallet-padding": "0 0 0 10px",
    "--button-primary-bg": "#3454D1",
    "--button-primary-text": "#FFF",
    "--action-text-transform": "none",

    "--br-2": "2px",
    "--br-4": "4px",
    "--br-6": "6px",
    "--br-8": "8px",
    "--br-10": "10px",
    "--br-20": "20px",

    "--text-color-fg": "#fff",
    "--filter-highlight-bg": "#22B07D",
    "--filter-text-color": "#fff",
    "--filter-text-active-color": "#fff",
    "--filter-bg": "#253137",
    "--lobby-text-color": "#fff",
    "--lobby-help-color": "#fff",
    "--lobby-help-bg": "#045DE9",
    "--light-contrast-bg": "#f4f4f4",
    "--condensed-text-font-size": "16px",
    "--condensed-text-font-letter-spacing": "normal",
    "--heading-font-weight": "normal",

    "--lobby-font-family": "Barlow",


    "--br-diff-2": "2px",
    "--br-diff-4": "4px",
    "--br-diff-6": "6px",
    "--br-diff-8": "8px",
    "--br-diff-10": "10px",
    "--br-diff-20": "20px",

    "--promo-carousel-arrow-fill": "#fff",
    
  } 
}

export const attheraces: Theme  = {
  name: "attheraces",
  properties: {
    "--default-font-family": "Barlow",
    "--heading-font-family": "Barlow-SemiBold",
    "--label-font-family": "Barlow-Medium",
    "--heavey-font-family": "Barlow-SemiBold",
    "--condensed-font-family": "BarlowCondensed-Medium",
    "--condensed-normal-font-family": "BarlowCondensed",
    "--default-font-size": "14px",
    "--logoSrc": "url(../../assets/images/attheraces.svg)",
    "--logo-width": "65%",
    "--logo-header-width": "180px",
    "--logo-height": "40px",
    "--logo-height-small": "40px",

    "--default-page-width": "375px",
    "--default-padding": "20px",

    "--background-default": "#034654",
    "--background-secondary": "#F5AC2C",
    "--background-tertiary": "1px solid #e4e8ed",
    "--background-light": "#FFFFFF",
    "--background-light-grey": "#f5f6f8",
    "--background-mygames-buffer": "#034654",
    "--survivor-picker-text": "#051629",

    "--foreground-default": "#FFFFFF",
    "--foreground-secondary": "#051629",
    "--foreground-tertiary": "#566473",
    "--foreground-light": "#FFFFFF",
    "--foreground-dark": "black",
    "--foreground-highlight": "#f63e73",
    "--foreground-grey" : "#57606a",
    "--foreground-dark-grey" : "#4d5268",
    "--foreground-tab": "#2a3e53",
    "--color-on-dark-bg": "#FFFFFF",
    "--foreground-dark-barlow": "#171717",

    "--error-default": "#EF3E36",
    "--error-dark": "#800600",
    "--error-light": "#FFCECC",

    "--top-drop-shadow": "0 -8px 7px 0 rgba(0, 0, 0, 0.5)",
    "--button-primary-color": "#000000",
    "--button-inverted": "#000",
    "--button-inverted-border": "#000",
    "--button-secondary-color": "#ffffff",
    "--button-border-color": "#CB0023",
    "--input-border-color" : "#DDDDDD",
    "--survivor-border-color" : "#376aed",
    "--foreground-footer": "#e4e8ed",
    "--link-color": "#286A45",
    "--item-selected": "#04124E",
    "--picker-selected": "#04124E",
    "--picker-selected-color": "#FFF",
    "--sgs-picker-selected": "#2A373E",
    "--sgs-picker-selected-color": "#fff",
    "--partner-color": "#E10A0A",

    "--contrast-color": "#CB0023",
    "--affiliate-header-color": "#034654",

    "--button-consent-color": "#04124E",
    "--button-consent-text-color": "#FFF",
    
    "--header-wallet-color": "#22b07d",

    "--horse-picker-bar": "#CB0023",

    "--header-menu-bg": "#262626",
    "--golf-leaderboard-tabs-background": "#FF9900",
    
    "--horse-fib-picker-tab-background": "#D9D9D9",
    "--horse-fib-picker-tab-text": "#1F375B",
    "--horse-fib-picker-tab-background-selected": "#1493FF",
    "--horse-fib-picker-tab-background-branch-selected": "#1493FF",
    "--horse-fib-picker-tab-background-last": "#A6CEF3",

    "--horse-fib-picker-slider-bg": "#D9D9D9",
    "--horse-fib-picker-slider-bg-active": "#3454D1",
    "--horse-fib-picker-slider-bg-focus": "#2196F3",
    "--horse-fib-picker-slider-text": "#484848",
    "--horse-fib-picker-slider-text-active": "#fff",
    
    "--horse-fib-picker-horse-selected-bg": "#000",
    "--horse-fib-picker-horse-selected-pts": "#3454D1",
    "--horse-fib-picker-horse-selected-pts-color": "#fff",

    "--horse-fib-inplay-ticket-highlight": "#F99F4A",

    "--header-padding": "16px 10px",
    "--header-logo-margin": "0px",
    "--header-align-items": "center",
    "--header-menu-margin": "5px 0 0 0",
    "--header-wallet-padding": "0 0 0 10px",
     "--button-primary-bg": "#3454D1",
     "--button-primary-text": "#FFF",
    "--action-text-transform": "none",

    "--br-2": "2px",
    "--br-4": "4px",
    "--br-6": "6px",
    "--br-8": "8px",
    "--br-10": "10px",
    "--br-20": "20px",

    "--text-color-fg": "#fff",
    "--filter-highlight-bg": "#22B07D",
    "--filter-text-color": "#fff",
    "--filter-text-active-color": "#fff",
    "--filter-bg": "#253137",
    "--lobby-text-color": "#fff",
    "--lobby-help-color": "#fff",
    "--lobby-help-bg": "#045DE9",
    "--light-contrast-bg": "#f4f4f4",
    "--condensed-text-font-size": "16px",
    "--condensed-text-font-letter-spacing": "normal",
    "--heading-font-weight": "normal",

    "--lobby-font-family": "Barlow",


    "--br-diff-2": "2px",
    "--br-diff-4": "4px",
    "--br-diff-6": "6px",
    "--br-diff-8": "8px",
    "--br-diff-10": "10px",
    "--br-diff-20": "20px",

    "--promo-carousel-arrow-fill": "#fff",
    
  } 
}

export const nationalworld: Theme  = {
  name: "nationalworld",
  properties: {
    "--default-font-family": "Barlow",
    "--heading-font-family": "Barlow-SemiBold",
    "--label-font-family": "Barlow-Medium",
    "--heavey-font-family": "Barlow-SemiBold",
    "--condensed-font-family": "BarlowCondensed-Medium",
    "--condensed-normal-font-family": "BarlowCondensed",
    "--default-font-size": "14px",
    "--logoSrc": "url(../../assets/images/nationalworld.svg)",
    "--logo-width": "65%",
    "--logo-header-width": "220px",
    "--logo-height": "18px",
    "--logo-height-small": "18px",

    "--default-page-width": "375px",
    "--default-padding": "20px",

    "--background-default": "#000",
    "--background-secondary": "#3C6EF4",
    "--background-tertiary": "1px solid #e4e8ed",
    "--background-light": "#FFFFFF",
    "--background-light-grey": "#f5f6f8",
    "--background-mygames-buffer": "#000",
    "--survivor-picker-text": "#000",

    "--foreground-default": "#FFFFFF",
    "--foreground-secondary": "#051629",
    "--foreground-tertiary": "#566473",
    "--foreground-light": "#FFFFFF",
    "--foreground-dark": "black",
    "--foreground-highlight": "#f63e73",
    "--foreground-grey" : "#57606a",
    "--foreground-dark-grey" : "#4d5268",
    "--foreground-tab": "#2a3e53",
    "--color-on-dark-bg": "#FFFFFF",
    "--foreground-dark-barlow": "#171717",

    "--error-default": "#EF3E36",
    "--error-dark": "#800600",
    "--error-light": "#FFCECC",

    "--top-drop-shadow": "0 -8px 7px 0 rgba(0, 0, 0, 0.5)",
    "--button-primary-color": "#FFF",
    "--button-inverted": "#000",
    "--button-inverted-border": "#000",
    "--button-secondary-color": "#ffffff",
    "--button-border-color": "#DB2955",
    "--input-border-color" : "#DDDDDD",
    "--survivor-border-color" : "#376aed",
    "--foreground-footer": "#e4e8ed",
    "--link-color": "#22B07D",
    "--item-selected": "#DB2955",
    "--picker-selected": "#DB2955",
    "--picker-selected-color": "#FFF",
    "--sgs-picker-selected": "#DB2955",
    "--sgs-picker-selected-color": "#fff",
    "--partner-color": "#E10A0A",

    "--contrast-color": "#DB2955",
    "--affiliate-header-color": "#000",

    "--button-consent-color": "#DB2955",
    "--button-consent-text-color": "#FFF",
    
    "--header-wallet-color": "#22b07d",

    "--horse-picker-bar": "#DB2955",

    "--header-menu-bg": "#262626",
    "--golf-leaderboard-tabs-background": "#FF9900",
    
    "--horse-fib-picker-tab-background": "#D9D9D9",
    "--horse-fib-picker-tab-text": "#1F375B",
    "--horse-fib-picker-tab-background-selected": "#1493FF",
    "--horse-fib-picker-tab-background-branch-selected": "#1493FF",
    "--horse-fib-picker-tab-background-last": "#A6CEF3",

    "--horse-fib-picker-slider-bg": "#D9D9D9",
    "--horse-fib-picker-slider-bg-active": "#3454D1",
    "--horse-fib-picker-slider-bg-focus": "#2196F3",
    "--horse-fib-picker-slider-text": "#484848",
    "--horse-fib-picker-slider-text-active": "#fff",
    
    "--horse-fib-picker-horse-selected-bg": "#000",
    "--horse-fib-picker-horse-selected-pts": "#3454D1",
    "--horse-fib-picker-horse-selected-pts-color": "#fff",

    "--horse-fib-inplay-ticket-highlight": "#F99F4A",

    "--header-padding": "16px 10px",
    "--header-logo-margin": "0px",
    "--header-align-items": "center",
    "--header-menu-margin": "5px 0 0 0",
    "--header-wallet-padding": "0 0 0 10px",
     "--button-primary-bg": "#3454D1",
     "--button-primary-text": "#FFF",
     "--action-text-transform": "none",

     "--br-2": "2px",
     "--br-4": "4px",
     "--br-6": "6px",
     "--br-8": "8px",
     "--br-10": "10px",
     "--br-20": "20px",

     "--text-color-fg": "#fff",
    "--filter-highlight-bg": "#22B07D",
    "--filter-text-color": "#fff",
    "--filter-text-active-color": "#fff",
    "--filter-bg": "#253137",
    "--lobby-text-color": "#fff",
    "--lobby-help-color": "#fff",
    "--lobby-help-bg": "#045DE9",
    "--light-contrast-bg": "#f4f4f4",
    "--condensed-text-font-size": "16px",
    "--condensed-text-font-letter-spacing": "normal",
    "--heading-font-weight": "normal",

    "--lobby-font-family": "Barlow",


    "--br-diff-2": "2px",
    "--br-diff-4": "4px",
    "--br-diff-6": "6px",
    "--br-diff-8": "8px",
    "--br-diff-10": "10px",
    "--br-diff-20": "20px",

    "--promo-carousel-arrow-fill": "#fff",
    
  } 
}

export const fitzdaressportsbook: Theme  = {
  name: "fitzdaressportsbook",
  properties: {
    "--default-font-family": "Barlow",
    "--heading-font-family": "Barlow-SemiBold",
    "--label-font-family": "Barlow-Medium",
    "--heavey-font-family": "Barlow-SemiBold",
    "--condensed-font-family": "BarlowCondensed-Medium",
    "--condensed-normal-font-family": "BarlowCondensed",

    "--default-font-size": "14px", 
    "--logoSrc": "url(../../assets/images/bettrev.svg)",
    "--logo-width": "65%",
    "--logo-header-width": "160px",
    "--logo-height": "68px",
    "--logo-height-small": "56px",

    "--default-page-width": "375px",
    "--default-padding": "20px",

    "--background-default": "#051629", 
    "--background-secondary": "#FFD700",
    "--background-tertiary": "2px solid #000",
    "--background-light": "#FFFFFF",
    "--background-light-grey": "#f5f6f8",
    "--background-mygames-buffer": "#f4f4f4", 
    "--survivor-picker-text": "#051629",

    "--foreground-default": "#ffffff",
    "--foreground-secondary": "#051629",
    "--foreground-tertiary": "#566473",
    "--foreground-light": "#FFFFFF",
    "--foreground-dark": "black",
    "--foreground-highlight": "#f63e73",
    "--foreground-grey" : "#57606a",
    "--foreground-dark-grey" : "#4d5268",
    "--foreground-tab": "#2a3e53",
    "--color-on-dark-bg": "#FFFFFF",
    "--foreground-dark-barlow": "#171717",

    "--error-default": "#EF3E36",
    "--error-dark": "#800600",
    "--error-light": "#FFCECC",

    "--top-drop-shadow": "0 -8px 7px 0 rgba(0, 0, 0, 0.5)",
    "--button-primary-color": "#000000",
    "--button-inverted": "#000",
    "--button-inverted-border": "#000",
    "--button-secondary-color": "#ffffff",
    "--button-border-color": "#0A8C44",
    "--input-border-color" : "#DDDDDD",
    "--survivor-border-color" : "#376aed",
    "--foreground-footer": "#e4e8ed",
    "--link-color": "#286A45",
    "--item-selected": "#FFCB77",
    "--picker-selected": "#FFCB77",
    "--picker-selected-color": "#000",
    "--sgs-picker-selected": "#2A373E",
    "--sgs-picker-selected-color": "#fff",
    "--partner-color": "#E10A0A",

    "--contrast-color": "#0A8C44",
    "--affiliate-header-color": "#004346",

    "--button-consent-color": "#FFCB77",
    "--button-consent-text-color": "#000",

    
    "--header-wallet-color": "#22b07d",

    "--horse-picker-bar": "#004346",

    "--horse-leaderboard-tab": "#014C6B",
    "--horse-inplay-header-font-size": "24px",
    "--horse-inplay-header-line-height": "29px",
    // "--horse-inplay-header-font-size": "17px",
    // "--horse-inplay-header-line-height": "21px",

    "--header-menu-bg": "#004346",
    "--golf-leaderboard-tabs-background": "#0A8C44",
    
    // "--horse-fib-picker-tab-background": "#c8c8c8",
    // "--horse-fib-picker-tab-text": "#004346",
    // "--horse-fib-picker-tab-background-selected": "#1493FF",
    // "--horse-fib-picker-tab-background-branch-selected": "#E4E8EE",
    // "--horse-fib-picker-tab-background-last": "transparent",

    // "--horse-fib-picker-slider-bg": "#D9D9D9",
    // "--horse-fib-picker-slider-text-active": "#fff",

    // "--horse-fib-picker-slider-bg-active": "#3454D1",
    // "--horse-fib-picker-slider-bg-focus": "#2196F3",
    // "--horse-fib-picker-slider-text": "#484848",

    "--horse-fib-picker-tab-background": "#D9D9D9",
    "--horse-fib-picker-tab-text": "#1F375B",
    "--horse-fib-picker-tab-background-selected": "#1493FF",
    "--horse-fib-picker-tab-background-branch-selected": "#1493FF",
    "--horse-fib-picker-tab-background-last": "#A6CEF3",

    "--horse-fib-picker-slider-bg": "#D9D9D9",
    "--horse-fib-picker-slider-bg-active": "#3454D1",
    "--horse-fib-picker-slider-bg-focus": "#2196F3",
    "--horse-fib-picker-slider-text": "#484848",
    "--horse-fib-picker-slider-text-active": "#fff",

    "--horse-fib-picker-horse-selected-bg": "#000",
    "--horse-fib-picker-horse-selected-pts": "#3454D1",
    "--horse-fib-picker-horse-selected-pts-color": "#fff",

    "--horse-fib-inplay-ticket-highlight": "#F99F4A",

    "--header-padding": "16px 0 0 0",
    "--header-logo-margin": "0 0 0 10px",
    "--header-align-items": "flex-start",
    "--header-menu-margin": "5px 10px 0 0",
    "--header-wallet-padding": "10px 0 0 10px",
    "--button-primary-bg": "#FFD700",
    "--button-primary-text": "black",
    "--action-text-transform": "lowercase",

    "--br-2": "2px",
    "--br-4": "4px",
    "--br-6": "6px",
    "--br-8": "8px",
    "--br-10": "10px",
    "--br-20": "20px",


    "--br-diff-2": "0px",
    "--br-diff-4": "0px",
    "--br-diff-6": "0px",
    "--br-diff-8": "0px",
    "--br-diff-10": "0px",
    "--br-diff-20": "0px",

    "--promo-carousel-arrow-fill": "#000",
    
    "--text-color-fg": "#fff",
    "--filter-highlight-bg": "#fff",
    "--filter-text-color": "#000",
    "--filter-text-active-color": "#FF4500",
    "--filter-bg": "#CDCBC7",
    "--lobby-text-color": "#000",
    "--lobby-help-color": "#000",
    "--lobby-help-bg": "#FFD700",
    "--light-contrast-bg": "#fff",
    "--condensed-text-font-size": "16px",
    "--condensed-text-font-letter-spacing": "normal",
    "--heading-font-weight": "normal",

    "--lobby-font-family": "system-ui",
  }
}