export interface Abbreviations {
  name: string;
  properties: any;
}

export const teamAbbreviations: Abbreviations = {
  name: 'teamAbbreviations',
  properties: {
    'Arsenal': 'ARS',
    'Aston Villa': 'AVL',
    'AFC Bournemouth': 'BOU',
    'Bournemouth': 'BOU',
    'Brighton': 'BHA',
    "Brighton & Hove Albion": "BHA",
    'Brentford': 'BRE',
    'Burnley': 'BUR',
    'Chelsea': 'CHE',
    'Crystal Palace': 'CRY',
    'C. Palace': 'CRY',
    'Everton': 'EVE',
    'Fulham': 'FUL',
    'Leeds': 'LEE',
    'Leicester': 'LEI',
    'Liverpool': 'LIV',
    'Luton Town': 'LUT',
    'Man City': 'MCI',
    'Man Utd': 'MUN',
    'Manchester United': 'MUN',
    'Newcastle': 'NEW',
    'Newcastle United': 'NEW',
    'Norwich': 'NOR',
    'Nottingham Forest': 'NFO',
    'Nottm Forest': 'NFO',
    'Sheffield United': 'SHU',
    'Sheffield Utd': 'SHU',
    'Southampton': 'SOU',
    'Tottenham Hotspur': 'TOT',
    'Tottenham': 'TOT',
    'Watford': 'WAT',
    'West Brom': 'WBA',
    'West Ham United': 'WHU',
    'West Ham': 'WHU',
    'Wolverhampton Wanderers': 'WOL',
    'Wolves': 'WOL',
    // 'Portugal' : 'POR',
    'Hungary' : 'HUN',
    // 'Germany' : 'GER',
    // 'Spain' : 'ESP',
    'Slovakia' : 'SVK',
    'Sweden' : 'SWE',
    // 'Poland' : 'POL',
    // 'Netherlands' : 'NED',
    'Ukraine' : 'UKR',
    'Austria' : 'AUT',
    'North Macedonia' : 'MKD',
    // 'England' : 'ENG',
    'Czech Republic' : 'CZE',
    'Scotland' : 'SCO',
    // 'Croatia' : 'CRO',
    'Finland' : 'FIN',
    // 'Denmark' : 'DEN',
    // 'Belgium' : 'BEL',
    'Russia' : 'RUS',
    // 'Wales' : 'WAL',
    // 'Switzerland' : 'SUI',
    'Italy' : 'ITA',
    'Turkey': 'TUR',
    // 'France': 'FRA',
    'AC Milan': 'MIL',
    'Ajax Amsterdam': 'AJA',
    'Ajax': 'AJA',
    'Atalanta': 'ATT',
    'Atletico Madrid': 'ATM',
    'Atletico': 'ATM',
    'Barcelona': 'FCB',
    'Bayern Munich': 'BAY',
    'Besiktas': 'BES',
    'Borussia Dortmund': 'DOR',
    'Dortmund': 'DOR',
    'Club Brugge': 'BRU',
    'Dynamo Kyiv': 'DYK',
    'FC Porto': 'POR',
    'Inter Milan': 'INT',
    'Juventus': 'JUV',
    'Lille': 'LIL',
    'Manchester City': 'MCI',
    'Paris St Germain': 'PSG',
    'RB Leipzig': 'RBL',
    'Real Madrid': 'MAD',
    'Sevilla': 'SEV',
    'Sporting Lisbon': 'SLI',
    'Vfl Wolfsburg': 'WOB',
    'Wolfsburg': 'WOB',
    'Villarreal': 'VIL',
    'Zenit St. Petersburg': 'ZSP',
    'Zenit': 'ZSP',
    'Benfica' : 'SLB',
    'Brondby' : 'BIF',
    'Dinamo Zagreb': 'DZA',
    'FC Salzburg': 'RBS',
    'Salzburg': 'RBS',
    'Ferencvaros': 'FER',
    'Ludogorets Razgrad': 'LUD',
    'Malmoe FF': 'MAL',
    'Monaco': 'AMO',
    'PSV Eindhoven': 'PSV',
    'Shakhtar Donetsk': 'SHK',
    'Sheriff Tiraspol': 'SHE',
    'Shakhtar': 'SHK',
    'FC Sheriff': 'SHE',
    'BSC Young Boys': 'YBO',
    'Young Boys': 'YBO',
    'Club Bruges': 'BRU',
    'PSG': 'PSG',
    'Sporting CP': 'SLI',
    'Inter': 'INT',
    "Argentina": "ARG",
    "Australia": "AUS",
    "Belgium": "BEL",
    "Brazil": "BRA",
    "Cameroon": "CMR",
    "Canada": "CAN",
    "Costa Rica": "CRC",
    "Croatia": "CRO",
    "Denmark": "DEN",
    "Ecuador": "ECU",
    "England": "ENG",
    "France": "FRA",
    "Germany": "GER",
    "Ghana": "GHA",
    "Iran": "IRN",
    "Japan": "JPN",
    "Mexico": "MEX",
    "Morocco": "MAR",
    "Netherlands": "NED",
    "Poland": "POL",
    "Portugal": "POR",
    "Qatar": "QAT",
    "Saudi Arabia": "KSA",
    "Senegal": "SEN",
    "Serbia": "SRB",
    "South Korea": "KOR",
    "Spain": "ESP",
    "Switzerland": "SUI",
    "Tunisia": "TUN",
    "USA": "USA",
    "Uruguay": "URU",
    "Wales": "WAL",
    "Birmingham City": "BRC",
    "Blackburn": "BBR",
    "Bristol City": "BSC",
    "Cardiff": "CDF",
    "Coventry": "CVC",
    "Huddersfield": "HDD",
    "Hull": "HUL",
    "Ipswich": "IPS",
    "Middlesbrough": "MID",
    "Millwall": "MLW",
    "Plymouth": "PLA",
    "Preston NE": "PNE",
    "QPR": "QPR",
    "Rotherham": "RTU",
    "Sheff Wed": "SHF",
    "Stoke": "STK",
    "Sunderland": "SUN",
    "Swansea": "SWA",
    'Paris Saint Germain': 'PSG',
    "Paris Saint-Germain": "PSG",
    "FC Koebenhavn": "COP",
    "Galatasaray": "GAL",
    "Lens": "LEN",
    "FK Crvena Zvezda": "RSB",
    "Bayer Leverkusen": "B04",

    /* EFL championship */
    "Ipswich Town": "IPS",
    "Hull City": "HUL",
    "Coventry City": "CVC",
    "Cardiff City": "CDF",
    "Preston North End": "PNE",
    "Swansea City": "SWA",
    "Blackburn Rovers": "BBR",
    "Plymouth Argyle": "PLA",
    "Stoke City": "STK",
    "Huddersfield Town": "HDD",
    "Sheffield Wednesday": "SHF",
    "Queens Park Rangers": "QPR",
    "Rotherham United": "RTU",
    "West Bromwich Albion": "WBA",
    "Leeds United": "LEE",
    "Norwich City": "NOR",
    "Leicester City": "LEI",

    /* Basketball */
    "Milwaukee Bucks": "MIL",
    "Chicago Bulls": "CHI",
    "Orlando Magic": "ORL",
    "Memphis Grizzlies": "MEM",
    "Toronto Raptors": "TOR",
    "New Orleans Pelicans": "NOP",
    "Charlotte Hornets": "CHA",
    "San Antonio Spurs": "SAS",
    "Minnesota Timberwolves": "MIN",
    "Oklahoma City Thunder": "OKC",
    "Houston Rockets": "HOU",
    "Utah Jazz": "UTA",
    "Portland Trail Blazers": "POR",
    "Dallas Mavericks": "DAL",
    "Cleveland Cavaliers": "CLE",
    "Detroit Pistons": "DET",
    "Indiana Pacers": "IND",
    "Atlanta Hawks": "ATL",
    "Los Angeles Clippers": "LAC",
    "Sacramento Kings": "SAC",
    "Washington Wizards": "WAS",
    "Brooklyn Nets": "BKN",
    "Los Angeles Lakers": "LAL",
    "Boston Celtics": "BOS",
    "Miami Heat": "MIA",
    "Denver Nuggets": "DEN",
    "Philadelphia 76ers": "PHI",
    "Golden State Warriors": "GSW",
    "Phoenix Suns": "PHX",
    "New York Knicks": "NYK",

    // Euros '24
    "Albania": "ALB",
    "Czechia": "CZE",
    "Georgia": "GEO",
    "Romania": "ROU",
    "Slovenia": "SVN",
    "Turkiye": "TUR"
  }
};

export const teamTranslations: Abbreviations = {
  name: 'teamTranslations',
  properties: {
    "Arsenal":        "Arsenal",
    "Aston Villa":    "Aston Villa",
    "AFC Bournemouth":    "Bournemouth",
    "Brighton & Hove Albion": "Brighton",
    "Brentford": "Brentford",
    "Burnley":        "Burnley",
    "Chelsea":        "Chelsea",
    "Crystal Palace": "C. Palace",
    "Everton":        "Everton",
    "Leeds United":   "Leeds",
    "Leicester City": "Leicester",
    "Liverpool": "Liverpool",
    "Luton Town": "Luton Town",
    "Manchester City": "Man City",    
    "Manchester United": "Man Utd",
    "Newcastle United":  "Newcastle",
    "Norwich City":      "Norwich",
    "Sheffield United":  "Sheffield Utd",
    "Southampton":       "Southampton",
    "Tottenham Hotspur": "Tottenham",
    "Watford":           "Watford",
    "West Bromwich Albion":      "West Brom",
    "West Ham United":       "West Ham",
    "Wolverhampton Wanderers": "Wolves",   
    'North Macedonia': 'N. Macedonia',
    'Czech Republic': 'Czech Rep',
    'Zenit St. Petersburg': 'Zenit',
    'Borussia Dortmund': 'Dortmund',
    'Shakhtar Donetsk':  'Shakhtar',
    'Paris Saint-Germain':'PSG',
    'Atletico Madrid': 'Atletico',
    'BSC Young Boys': 'Young Boys',
    'Nottingham Forest': 'Nottm Forest',
    "Birmingham City": "Birmingham",
    "Blackburn Rovers": "Blackburn",
    "Bristol City": "Bristol City",
    "Cardiff City": "Cardiff",
    "Coventry City": "Coventry",
    "Huddersfield Town": "Huddersfield",
    "Hull City": "Hull",
    "Ipswich Town": "Ipswich",
    "Leeds": "Leeds",
    "Leicester": "Leicester",
    "Middlesbrough": "Middlesbrough",
    "Millwall": "Millwall",
    "Plymouth Argyle": "Plymouth",
    "Preston North End": "Preston NE",
    "Queens Park Rangers": "QPR",
    "Rotherham United": "Rotherham",
    "Sheffield Wednesday": "Sheff Wed", 
    "Stoke City": "Stoke",
    "Sunderland": "Sunderland",
    "Swansea City": "Swansea",
    "West Brom": "West Brom",
    "Fulham": "Fulham",
    'AC Milan': 'AC Milan',
    'Paris Saint Germain': 'PSG',
    "Bayern Munich": 'Bayern',
    "Club Bruges": "Bruges",
    "Benfica": "Benfica",
    "Eintracht Frankfurt": "Frankfurt",
    "Napoli": "Napoli",
    "Real Madrid": "Madrid",
    "Inter Milan": "Inter",
    "FC Porto": "Porto",
    "RB Leipzig": "RB Leipzig",
    "FC Koebenhavn": "Copenhagen",
    "Galatasaray": "Galatasaray",
    "Sevilla": "Sevilla",
    "PSV Eindhoven": "PSV",
    "Lens": "Lens",
    "FK Crvena Zvezda": "Belgrade",
    "Young Boys": "Young Boys",
    "Bayer Leverkusen": "Leverkusen",
    "Atalanta": "Atalanta"
  }
}

// export const euroleagueTranslations: Abbreviations = {
//   name: 'euroleagueTranslations',
//   properties: {
//     'North Macedonia': 'N. Macedonia',
//     'Czech Republic': 'Czech Rep'
//   }
// }

// export const championsLeagueTranslations: Abbreviations = {
//   name: 'championsLeagueTranslations',
//   properties: {
//     'Zenit St. Petersburg': 'Zenit',
//     'Borussia Dortmund': 'Dortmund',
//     'Shakhtar Donetsk':  'Shakhtar',
//     'Paris Saint-Germain':'PSG'
//   }
// }

// export const championsLeagueTeams: Abbreviations = {
//   name: 'championsLeagueTeams',
//   properties: {
//     'AC Milan': 'MIL',
//     'Ajax Amsterdam': 'AJA',
//     'Atalanta': 'ATT',
//     'Atletico Madrid': 'ATM',
//     'Barcelona': 'FCB',
//     'Bayern Munich': 'BAY',
//     'Besiktas': 'BES',
//     'Borussia Dortmund': 'DOR',
//     'Chelsea': 'CHE',
//     'Club Brugge': 'BRU',
//     'Dynamo Kyiv': 'DYK',
//     'FC Porto': 'POR',
//     'Inter Milan': 'INT',
//     'Juventus': 'JUV',
//     'Lille': 'LIL',
//     'Liverpool': 'LIV',
//     'Manchester City': 'MCI',
//     'Manchester United': 'MUN',
//     'Paris St Germain': 'PSG',
//     'RB Leipzig': 'RBL',
//     'Real Madrid': 'MAD',
//     'Sevilla': 'SEV',
//     'Sporting Lisbon': 'SLI',
//     'Vfl Wolfsburg': 'WOB',
//     'Wolfsburg': 'WOB',
//     'Villarreal': 'VIL',
//     'Zenit St. Petersburg': 'ZSP',
//     'Zenit': 'ZSP',
//     'Benfica' : 'SLB',
//     'Brondby' : 'BIF',
//     'Dinamo Zagreb': 'DZA',
//     'FC Salzburg': 'RBS',
//     'Salzburg': 'RBS',
//     'Ferencvaros': 'FER',
//     'Ludogorets Razgrad': 'LUD',
//     'Malmoe FF': 'MAL',
//     'Monaco': 'AMO',
//     'PSV Eindhoven': 'PSV',
//     'Shakhtar Donetsk': 'SHK',
//     'Sheriff Tiraspol': 'SHE',
//     'BSC Young Boys': 'YBO',
//   }
// }


// export const euroLeagueTeams: Abbreviations = {
//     name: 'euroLeagueTeams',
//     properties: {
//       'Portugal' : 'POR',
//       'Hungary' : 'HUN',
//       'Germany' : 'GER',
//       'Spain' : 'ESP',
//       'Slovakia' : 'SVK',
//       'Sweden' : 'SWE',
//       'Poland' : 'POL',
//       'Netherlands' : 'NED',
//       'Ukraine' : 'UKR',
//       'Austria' : 'AUT',
//       'North Macedonia' : 'MKD',
//       'England' : 'ENG',
//       'Czech Republic' : 'CZE',
//       'Scotland' : 'SCO',
//       'Croatia' : 'CRO',
//       'Finland' : 'FIN',
//       'Denmark' : 'DEN',
//       'Belgium' : 'BEL',
//       'Russia' : 'RUS',
//       'Wales' : 'WAL',
//       'Switzerland' : 'SUI',
//       'Italy' : 'ITA',
//       'Turkey': 'TUR',
//       'France': 'FRA'
//     }
// };

export const wcTeamAbbreviations: Abbreviations = {
  name: 'wcTeamAbbreviations',
  properties: {
      "Argentina": "ARG",
      "Australia": "AUS",
      "Belgium": "BEL",
      "Brazil": "BRA",
      "Cameroon": "CMR",
      "Canada": "CAN",
      "Costa Rica": "CRC",
      "Croatia": "CRO",
      "Denmark": "DEN",
      "Ecuador": "ECU",
      "England": "ENG",
      "France": "FRA",
      "Germany": "GER",
      "Ghana": "GHA",
      "Iran": "IRN",
      "Japan": "JPN",
      "Mexico": "MEX",
      "Morocco": "MAR",
      "Netherlands": "NED",
      "Poland": "POL",
      "Portugal": "POR",
      "Qatar": "QAT",
      "Saudi Arabia": "KSA",
      "Senegal": "SEN",
      "Serbia": "SRB",
      "South Korea": "KOR",
      "Spain": "ESP",
      "Switzerland": "SUI",
      "Tunisia": "TUN",
      "USA": "USA",
      "Uruguay": "URU",
      "Wales": "WAL"
  },
};

export const sgsTeamAbbreviations: Abbreviations = {
  name: 'sgsTeamAbbreviations',
  properties: {
    "Arsenal": "Arsenal",
    "Aston Villa": "Villa",
    "Fulham": "Fulham",
    "AFC Bournemouth": "B'mouth",
    'Barcelona': 'FCB',
    "Brighton & Hove Albion": "Brighton",
    "Brentford": "Brentford",
    "Burnley": "Burnley",
    "Chelsea": "Chelsea",
    "Crystal Palace": "Palace",
    "Everton": "Everton",
    "Leeds United": "Leeds",
    "Leicester City": "Leicester",
    "Liverpool": "Liverpool",
    "Luton Town": "Luton",
    "Manchester City": "Man City",
    "Manchester United": "Man Utd",
    "Newcastle United": "Newcastle",
    "Norwich City": "Norwich",
    "Sheffield United": "Sheff Utd",
    "Southampton": "Southampton",
    "Tottenham Hotspur": "Spurs",
    "Watford": "Watford",
    "West Bromwich Albion": "West Brom",
    "West Ham United": "West Ham",
    "Wolverhampton Wanderers": "Wolves",
    'Nottingham Forest': 'Forest',
    'AC Milan': 'AC Milan',
    'Paris Saint Germain': 'PSG',
    "Paris Saint-Germain": "PSG",
    "Bayern Munich": 'Bayern',
    "Borussia Dortmund": "Dortmund",
    "Club Bruges": "Bruges",
    "Benfica": "Benfica",
    "Eintracht Frankfurt": "Frankfurt",
    "Napoli": "Napoli",
    "Real Madrid": "Madrid",
    "Inter Milan": "Inter",
    "FC Porto": "Porto",
    "RB Leipzig": "RB Leipzig",
    "FC Koebenhavn": "Copenhagen",
    "Galatasaray": "Galatasaray",
    "Sevilla": "Sevilla",
    "PSV Eindhoven": "PSV",
    "Lens": "Lens",
    "FK Crvena Zvezda": "Belgrade",
    "Young Boys": "Young Boys",
    "Ipswich Town": "Ipswich",
    "West Brom": "West Brom",
    "Sunderland": "Sunderland",
    "Hull City": "Hull",
    "Coventry City": "Coventry",
    "Cardiff City": "Cardiff",
    "Bristol City": "Bristol City",
    "Middlesbrough": "Middlesbrough",
    "Preston North End": "Preston NE",
    "Millwall": "Millwall",
    "Swansea City": "Swansea",
    "Blackburn Rovers": "Blackburn",
    "Plymouth Argyle": "Plymouth",
    "Stoke City": "Stoke",
    "Birmingham City": "Birmingham",
    "Huddersfield Town": "Huddersfield",
    "Sheffield Wednesday": "Sheff Wed",
    "Queens Park Rangers": "QPR",
    "Rotherham United": "Rotherham",
    "Bayer Leverkusen": "Leverkusen",
    "Atalanta": "Atalanta",
    "Scotland": "Scotland"
  }
}

export const sgsBasketballTeamAbbreviations: Abbreviations = {
  name: 'sgsBasketballTeamAbbreviations',
  properties: {
    "Atlanta Hawks": "Atlanta",
    "Boston Celtics": "Boston",
    "Brooklyn Nets": "Brooklyn",
    "Charlotte Hornets": "Charlotte",
    "Chicago Bulls": "Chicago",
    "Cleveland Cavaliers": "Cleveland",
    "Dallas Mavericks": "Dallas",
    "Denver Nuggets": "Denver",
    "Detroit Pistons": "Detroit",
    "Golden State Warriors": "Warriors",
    "Houston Rockets": "Houston",
    "Indiana Pacers": "Indiana",
    "Los Angeles Clippers": "LA Clippers",
    "Los Angeles Lakers": "LA Lakers",
    "Memphis Grizzlies": "Memphis",
    "Miami Heat": "Miami",
    "Milwaukee Bucks": "Milwaukee",
    "Minnesota Timberwolves": "Minnesota",
    "New Orleans Pelicans": "New Orleans",
    "New York Knicks": "New York",
    "Oklahoma City Thunder": "Oklahoma City",
    "Orlando Magic": "Orlando",
    "Philadelphia 76ers": "Philadelphia",
    "Phoenix Suns": "Phoenix",
    "Portland Trail Blazers": "Portland",
    "Sacramento Kings": "Sacramento",
    "San Antonio Spurs": "San Antonio",
    "Toronto Raptors": "Toronto",
    "Utah Jazz": "Utah",
    "Washington Wizards": "Washington"
  }
}

export const snlTeamsAbbreviations: Abbreviations = {
  name: 'snlTeamsAbbreviations',
  properties: {
      "United States": "USA",
      "Canada": "CAN",
      "Mexico": "MEX",
      "Costa Rica": "CRC",
      "Honduras": "HON",
      "Jamaica": "JAM",
      "El Salvador": "SLV",
      "Panama": "PAN",
      "Haiti": "HAI",
      "Trinidad and Tobago": "TRI",
      "Guatemala": "GUA",
      "Cuba": "CUB",
      "Barbados": "BAR",
      "Suriname": "SUR",
      "Guyana": "GUY",
      "Curacao": "CUW",
      "Bermuda": "BER",
      "Saint Kitts and Nevis": "SKN",
      "Saint Lucia": "LCA",
      "Saint Vincent and the Grenadines": "VIN",
      "Antigua and Barbuda": "ATG",
      "Dominica": "DMA",
      "Grenada": "GRN",
      "Montserrat": "MSR",
      "Aruba": "ARU",
      "Bahamas": "BAH",
      "British Virgin Islands": "VGB",
      "Cayman Islands": "CAY",
      "Puerto Rico": "PUR",
      "Saint Martin": "MAF",
      "Sint Maarten": "SXM",
      "Turks and Caicos Islands": "TCA",
      "United States Virgin Islands": "VIR",
      "Anguilla": "AIA",
      "Bonaire": "BES",
      "Saba": "SAB",
      "United Arab Emirates": "UAE",
      "Scotland": "SCO",
      "Saudi Arabia": "KSA",
      "Qatar": "QAT",
      "Bahrain": "BHR",
      "Oman": "OMA",
      "Kuwait": "KUW",
      "Iraq": "IRQ",
      "Jordan": "JOR",
      "United Kingdom": "GBR",
      "Germany": "GER",
    }
}